import React, { useMemo, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useGetSafetyAppApprovalQuery } from "slices/workflow/WorkFlowSlice";
import { BadgeStatus } from "helpers/badgeStatus";
import { getTextColor, STATUS_COLORS } from "helpers/common_helper";
import { generateApprovalUrl } from "helpers/common_helper";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

const SafetyAPPWorkApprovalsListView = () => {

  const [page, setPageState] = useState<{
    page: number,
    pageSize: number,
    search: string,
    status_name: string,
    start_date: string,
    due_date: string
  }>({
    page: 1,
    pageSize: 10,
    search: '',
    status_name: '',
    start_date: '',
    due_date: '',
  });

  const {
    data: workflowData,
    isLoading: isLoadingWorkflows,
    isFetching: isFetchingWorkFlow,
    // refetch: refetchWorkflows,
  } = useGetSafetyAppApprovalQuery(page);

  // Helper function to format 'type' (removes underscores and capitalizes)
  const formatType = (type: string) => {
    return type
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const columns = useMemo(
    () => [
      {
        Header: "Approval ID",
        accessor: "approval_id",
        filterable: false,
        Cell: ({
          row: {
            original: { permit_no },
          },
        }: any) => {
          const url = generateApprovalUrl(permit_no);
          return <Link
            to={`/${url}/${permit_no}?approval=true`}
            className="text-primary d-inline-block edit-item-btn view-task-status-class"
          >
            {permit_no}
          </Link>
        },
      },
      {
        Header: "Project Name",
        accessor: "project_name", 
        filterable: false,
      },
      {
        Header: "Permit No",
        accessor: "permit_no", 
        filterable: false,
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        filterable: false,
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        filterable: false,
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ row }: any) => (
          <span>{formatType(row.original.type)}</span> 
        ),
        filterable: false,
      },
      {
        Header: "Approval Status",
        accessor: "overall_approval_status",
        filterable: false,
        Cell: (cell: { value: any }) => (
          <>
            {cell.value ?
              <BadgeStatus value={cell.value} color={STATUS_COLORS[cell.value]} />
              : null}
          </>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const permitId = row.original.permit_no; 
          return (
            <div>
              <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item view">
                  <Link
                    to={`/homework-permit-detail-view/${permitId}`} 
                    className="text-info d-inline-block view-item-btn"
                  >
                    <i className="ri-file-fill fs-16"></i>
                  </Link>
                </li> */}
                {(IsValidPermissions(['VIEW_ALL_SAFETY_APP_APPROVALS']) || IsValidPermissions(['VIEW_ONLY_SAFETY_APP_APPROVALS'])) &&
                <li className="list-inline-item view" title="View Details">
                  <Link
                    to={`/homework-permit-details/${permitId}?approval=true`} 
                    className="text-info d-inline-block view-item-btn"
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </Link>
                </li>
                }
              </ul>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "WorkFlow Approvals";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Workflow Approvals List" pageTitle="SafetyApp" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Safety App Approvals List</h5>
                </CardHeader>
                <div className="card-body pt-0">
                {isLoadingWorkflows ? (
                    <Loader />
                  ) : (
                  <TableContainer
                    columns={columns}
                    data={workflowData.results || []}
                    isLoading={isFetchingWorkFlow}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={page.pageSize}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap mb-0"
                    theadClass="table-light text-muted"
                    thClass="table-light text-muted"
                    SearchPlaceholder="Search for Approvals..."
                    isPagination={false}
                    isServerPagination={true}
                    serverPaginationProps={{
                      count: workflowData?.count || 0,
                      next: workflowData?.next || null,
                      page_size: workflowData?.page_size || 10,
                      previous: workflowData?.previous || null,
                      current_page: workflowData?.current_page || 1,
                      total_page: workflowData?.total_page || 0
                    }}
                    setPageState={setPageState}
                  />
                )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default SafetyAPPWorkApprovalsListView;
