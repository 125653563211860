import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: any = {
  isUserLogout: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logoutUserSuccess(state, action) {
      state.isUserLogout = true;
    },
  },
});

export const {
  logoutUserSuccess,
} = loginSlice.actions;

export default loginSlice.reducer;
