import { useContext } from "react";
import { useDispatch } from "react-redux";
import { ServiceContext } from "context/ServiceContextProvider";
import { logoutUser } from "../slices/auth/login/thunk";
import { useNavigate } from "react-router-dom";

export const useAuthServices = () => {
  const dispatch: any = useDispatch();
  const { setselectedRoleId, setSelectedRoleName } = useContext(ServiceContext);
  const history = useNavigate();

  const getSelectedRole = () => {
    // Retrieve the selectedRole from sessionStorage
    // const storedRole = sessionStorage.getItem("selectedRole");
    const storedRole = localStorage.getItem("selectedRole");

    // Check if storedRole is not null
    if (storedRole) {
      const selectedRole = JSON.parse(storedRole);

      // Set the role_id and role_name in ServiceContext
      setselectedRoleId(selectedRole.role_id);
      setSelectedRoleName(selectedRole.role_name);
    } else {
      console.error("No role found in session storage");
      dispatch(logoutUser());
      history("/logout");
    }
  };

  return { getSelectedRole };
};
