import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Table,
  Badge
} from "reactstrap";
import classnames from "classnames";
import ValidationAlertStatic from "../../../ValidationAlertStatic";
import { useLocation } from 'react-router-dom';
import OverviewTab from "./OverviewTab";
import DocumentsTab from "./DocumentsTab";
import HealthDeclaration from "./HealthDeclaration";
// import ActivitiesTab from "Components/Common/ActivitesTab";
import { useGetAuditLogsQuery } from "slices/employee/employeeSlice";
import { getTextColor, STATUS_COLORS } from "helpers/common_helper";
import { Tooltip } from "react-tooltip";
import { ServiceContext } from "context/ServiceContextProvider";
import { useGenerateHomeworkPermitPDFMutation } from "slices/safetyApp/safetyappSlice";
import { useNavigate } from "react-router-dom";
// import SafetyAppOverviewTab from "pages/SafetyApp/WorkFlowApprovels/WorkFlowApprovelsOverview/OverviewTab";
// import { useGetSafetyAppApprovalByPermitIdQuery } from "slices/workflow/WorkFlowSlice";
import { BadgeStatus } from "helpers/badgeStatus";
import { IsValidPermissions } from "Routes/AuthProtected";
import { getErrorText } from "helpers/error_message";
import { useUpdateToolboxMeetingMutation, useCreateHealthDeclarationMutation } from "slices/safetyApp/safetyappSlice";

interface ToolBoxProps {
  toolboxMeetingByIdData: any;
  refetchTooboxById: any;
  isFetching: any;
}

const Summary: React.FC<ToolBoxProps> = ({
  toolboxMeetingByIdData,
  refetchTooboxById,
  isFetching,
}) => {
  const context = useContext(ServiceContext);
  const [location, setLocation] = useState<{ latitude: number | null; longitude: number | null }>({ latitude: null, longitude: null });
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [modalBackDrop, setModalBackDrop] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const { selectedRoleName, getEmployeeUUID } = context;
  const { data: auditLogs, isLoading: auditLogsLoading } = useGetAuditLogsQuery(
    { model_name: "toolbox", object_id: toolboxMeetingByIdData?.id }
  );
  const locationUrl = useLocation();
  const [healthDeclarationModal, setHealthDeclarationModal] = useState(false);
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const toggleConfirmCloseModal = () => setConfirmCloseModal(!confirmCloseModal);
  const toggleConfirmCancelModal = () => setConfirmCancelModal(!confirmCancelModal);
  const toggleHealthDeclarationModal = () => setHealthDeclarationModal(!healthDeclarationModal);
  const [approvalData, setApprovalData] = useState<any>([]);
  const [reason, setReason] = useState("");

  const [showPreview, setShowPreview] = useState(false);
  const [declareFit, setDeclareFit] = useState(true); // State to toggle between Fit and Not Fit
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [updateToolboxMeeting, { isLoading: updateLoading }] = useUpdateToolboxMeetingMutation();
  const [healthDeclaration, { isLoading: healthDeclarationLoading }] = useCreateHealthDeclarationMutation();
  // const {
  //   data: workFlowData,
  //   isLoading: projectLoading,
  //   refetch: refetchProject,
  // } = useGetSafetyAppApprovalByPermitIdQuery(getHomeworkPermitById?.hw_permit_no);

  // useEffect(() => {
  //   if (getHomeworkPermitById?.hw_permit_no) {
  //     refetchProject();
  //   }
  // }, [getHomeworkPermitById?.hw_permit_no, refetchProject]);

  // useEffect(() => {
  //   if (workFlowData) {
  //     setApprovalData(workFlowData);
  //   }
  // }, [workFlowData]);

  const getLocation = async () => {
    if ("geolocation" in navigator) {
      setIsPageLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setIsPageLoading(false);
        },
        (err) => {
          setValidationMessages((prev) => {
            const errorMessage = getErrorText('location-disabled');
            return prev.includes(errorMessage)
              ? prev
              : [...prev, errorMessage]
          });
          setModalBackDrop(true);
          setIsPageLoading(false);
        },
        { enableHighAccuracy: true }
      );
    } else {
      setValidationMessages((prev) => {
        const errorMessage = getErrorText('location-not-supported');
        return prev.includes(errorMessage)
          ? prev
          : [...prev, errorMessage]
      });
      setModalBackDrop(true);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if (toolboxMeetingByIdData) {
      setValidationMessages([]);
      getLocation();
    }
  }, [toolboxMeetingByIdData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(locationUrl.search);
    const approval = queryParams.get('approval');
    if (approval === 'true') {
      toggleTab("2");
    }
  }, [locationUrl]);


  const [generatePDF, { isLoading: isLoadingGeneratePDF }] = useGenerateHomeworkPermitPDFMutation();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleClosed = async (e: any) => {
    e.preventDefault();

    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    const toolBoxData = {
      cancel_comments: reason,
      cancel_latitude: location.latitude,
      cancel_longitude: location.longitude,
      report_status: 6,
    };

    try {
      await updateToolboxMeeting({ toolbox_id: toolboxMeetingByIdData?.toolbox_id, updatedMeetingData: toolBoxData }).unwrap();
      setReason("");
      toggleConfirmCloseModal();
      console.log("Approval closed successfully");
    } catch (error) {
      console.error("Error approving workflow", error);
      toggleConfirmCloseModal();
    }
  };

  const handleCancel = async (e: any) => {
    e.preventDefault();

    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    const toolBoxData = {
      cancel_comments: reason,
      cancel_latitude: location.latitude,
      cancel_longitude: location.longitude,
      report_status: 5,
    };

    try {
      await updateToolboxMeeting({ toolbox_id: toolboxMeetingByIdData?.toolbox_id, updatedMeetingData: toolBoxData }).unwrap();
      setReason("");
      toggleConfirmCancelModal();
      console.log("Approval cancelled successfully");
    } catch (error) {
      console.error("Error approving workflow", error);
      toggleConfirmCancelModal();
    }
  };

  const handleEmployeeSelection = (employeeId: any) => {
    setSelectedEmployees((prevSelected: any) =>
      prevSelected.includes(employeeId)
        ? prevSelected.filter((id: string) => id !== employeeId)
        : [...prevSelected, employeeId]
    );
  };

  const handleSelectAll = (selectAll: any) => {
    if (selectAll) {
      const allIds = toolboxMeetingByIdData?.toolbox_attendance?.map(
        (att: any) => att.employee.id
      );
      setSelectedEmployees(allIds || []);
    } else {
      setSelectedEmployees([]);
    }
  };

  const handleOpenHealthDeclaration = () => {
    setReason("");
    setDeclareFit(true);
    setSelectedEmployees([]);
    setShowPreview(false);
    toggleHealthDeclarationModal();
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const allEmployees = toolboxMeetingByIdData?.toolbox_attendance?.map((att: any) => att.employee.id) || [];
    const employeeIds = declareFit ? allEmployees : selectedEmployees;

    let payload;

    if (declareFit) {
      payload = {
        toolbox_meeting_id: toolboxMeetingByIdData?.toolbox_id,
        comments: reason,
        statuses: employeeIds.map((id: any) => {
          return { employee_id: id, health_status: 1 };
        }),
      };
    } else {
      const selectedSet = new Set(selectedEmployees);
      payload = {
        toolbox_meeting_id: toolboxMeetingByIdData?.toolbox_id,
        comments: reason,
        statuses: allEmployees.map((id: never) => ({
          employee_id: id,
          health_status: selectedSet.has(id) ? 2 : 1,
        })),
      };
    }

    console.log('payload', payload)

    try {
      await healthDeclaration(payload).unwrap();
      await refetchTooboxById();
      setReason("");
      setDeclareFit(true);
      setSelectedEmployees([]);
      setShowPreview(false);
      toggleHealthDeclarationModal();
      console.log("Health Declaration Declared successfully");
    } catch (error) {
      console.error("Error Health Declaration", error);
      setReason("");
      setDeclareFit(true);
      setSelectedEmployees([]);
      setShowPreview(false);
      toggleHealthDeclarationModal();
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".edit-task-class"
        place="bottom"
        content="Update Permit"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".close-report-class"
        place="bottom"
        content="Close Report"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".upadte-task-status-class"
        place="bottom"
        content="Update Task Status"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".export-task-status-class"
        place="bottom"
        content="Export Permit"
      />

      <div className="page-content pt-3">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4">
                <div>
                  <CardBody className="pb-0 px-4">
                    <Row className="mb-3">
                      <div className="col-md">
                        <Row className="align-items-center g-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h4 className="fw-bold">
                                {" "}
                                ToolBox Meeting Information
                              </h4>
                            </div>
                            <div>
                              {isPageLoading ? (
                                <>
                                  <Spinner size="sm" className="me-2" /> Loading Location Information...
                                </>
                              ) : (
                                IsValidPermissions(['ADD_TOOLBOX_MEETING']) && (
                                  <>
                                    {/* Show Health Declaration Button */}
                                    {(toolboxMeetingByIdData?.report_status_name === 'Linked' ||
                                      toolboxMeetingByIdData?.report_status_name === 'Submitted') && (
                                        <button
                                          className="btn btn-sm btn-success add-btn me-1 cancel-report-class"
                                          onClick={handleOpenHealthDeclaration}
                                        >
                                          <i className="ri-calendar-check-fill align-bottom me-1"></i> Health Declaration
                                        </button>
                                      )}

                                    {/* Show Close Toolbox Meeting Button */}
                                    {toolboxMeetingByIdData?.health_declaration?.length > 0 &&
                                      (toolboxMeetingByIdData?.report_status_name === 'Linked' ||
                                        toolboxMeetingByIdData?.report_status_name === 'Submitted') ? (
                                      <button
                                        className="btn btn-sm btn-warning add-btn me-1 cancel-report-class"
                                        onClick={toggleConfirmCloseModal}
                                      >
                                        <i className="ri-close-circle-line align-bottom me-1"></i> Close ToolBox Meeting
                                      </button>
                                    ) : null}

                                    {/* Show Cancel Toolbox Meeting Button */}
                                    {toolboxMeetingByIdData?.report_status_name === 'Draft' && (
                                      <button
                                        className="btn btn-sm btn-warning add-btn me-1 cancel-report-class"
                                        onClick={toggleConfirmCancelModal}
                                      >
                                        <i className="ri-close-circle-line align-bottom me-1"></i> Cancel ToolBox Meeting
                                      </button>
                                    )}
                                  </>
                                )
                              )}
                            </div>
                          </div>
                          <hr />
                          <div className="col-md">
                            <div className="d-flex justify-content-between">
                              <div>
                                <h4 className="fw-bold">
                                  ToobBox ID :{" "}
                                  <span className="text-muted">
                                    ({toolboxMeetingByIdData?.toolbox_id})
                                  </span>
                                </h4>
                              </div>

                              <div className="hstack gap-3 flex-wrap">
                                {toolboxMeetingByIdData?.report_status_name ?
                                  <>
                                    <div className="vr"></div>
                                    <div>
                                      Submission Status :{" "}
                                      <BadgeStatus value={toolboxMeetingByIdData?.report_status_name} color={STATUS_COLORS[toolboxMeetingByIdData?.report_status_name]} />
                                    </div>
                                  </>
                                  : null}
                                {toolboxMeetingByIdData?.approval_status_name ?
                                  <>
                                    <div className="vr"></div>
                                    <div>
                                      Approval Status :{" "}
                                      <BadgeStatus value={toolboxMeetingByIdData?.approval_status_name} color={STATUS_COLORS[toolboxMeetingByIdData?.approval_status_name]} />
                                    </div>
                                  </>
                                  : null}
                                <div className="vr"></div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    <Nav
                      className="nav-tabs-custom border-bottom-0"
                      role="tablist"
                      style={{ background: "lavender" }}
                    >
                      {(IsValidPermissions(['VIEW_ALL_TOOLBOX_MEETING']) || IsValidPermissions(['VIEW_ONLY_TOOLBOX_MEETING'])) &&
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "1" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("1");
                            }}
                            href="#"
                          >
                            Overview
                          </NavLink>
                        </NavItem>
                      }
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "2" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("2");
                          }}
                          href="#"
                        >
                          Health Declaration
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "3" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("3");
                          }}
                          href="#"
                        >
                          Documents
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "4" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("4");
                          }}
                          href="#"
                        >
                          Activities
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1">
                  <OverviewTab toolboxMeetingByIdData={toolboxMeetingByIdData} />
                </TabPane>
                <TabPane tabId="2">
                  <HealthDeclaration toolboxMeetingByIdData={toolboxMeetingByIdData} />
                </TabPane>
                <TabPane tabId="3">
                  <DocumentsTab getToolboxMeetingById={toolboxMeetingByIdData} />
                </TabPane>
                {/* <TabPane tabId="4">
                  <ActivitiesTab
                    activities={auditLogs}
                    isLoading={auditLogsLoading}
                  />
                </TabPane> */}

              </TabContent>
            </Col>
          </Row>
        </Container>

        {/* Confirm Close Modal */}
        <Modal isOpen={confirmCloseModal} toggle={toggleConfirmCloseModal}>
          <Form onSubmit={(e) => handleClosed(e)}>
            <ModalHeader toggle={toggleConfirmCloseModal}>
              Close ToolBox Meeting
            </ModalHeader>
            <ModalBody>
              <p>
                Are you sure you want to Close the ToolBox Meeting <b color="text-primary">{toolboxMeetingByIdData?.toolbox_id}</b>?
              </p>
              <FormGroup>
                <Label for="approvalComment">Add Comment to Close ToolBox Meeting</Label>
                <Input
                  required
                  type="textarea"
                  name="approvalComment"
                  id="approvalComment"
                  placeholder="Add a comment to Close ToolBox Meeting"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="success">
                {updateLoading ? <Spinner size="sm" /> : "Close ToolBox Meeting"}
              </Button>
              <Button color="secondary" onClick={toggleConfirmCloseModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>


        <Modal isOpen={confirmCancelModal} toggle={toggleConfirmCancelModal}>
          <Form onSubmit={(e) => handleCancel(e)}>
            <ModalHeader toggle={toggleConfirmCancelModal}>
              Cancel ToolBox Meeting
            </ModalHeader>
            <ModalBody>
              <p>
                Are you sure you want to Cancel the ToolBox Meeting <b color="text-primary">{toolboxMeetingByIdData?.toolbox_id}</b>?
              </p>
              <FormGroup>
                <Label for="approvalComment">Add Comment to Cancel ToolBox Meeting</Label>
                <Input
                  required
                  type="textarea"
                  name="approvalComment"
                  id="approvalComment"
                  placeholder="Add a comment to Cancel ToolBox Meeting"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="success">
                {updateLoading ? <Spinner size="sm" /> : "Cancel ToolBox Meeting"}
              </Button>
              <Button color="secondary" onClick={toggleConfirmCancelModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        {/* Confirm Health Declaration Modal */}
        <Modal size="lg" isOpen={healthDeclarationModal} toggle={toggleHealthDeclarationModal}>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <ModalHeader toggle={toggleHealthDeclarationModal}>
              Health Declaration
            </ModalHeader>
            <ModalBody>
              {!showPreview ? (
                <>
                  <p className="text-center">
                    Health Declaration for ToolBox Meeting <b color="text-primary">{toolboxMeetingByIdData?.toolbox_id}</b>
                  </p>
                  <FormGroup>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Input
                        type="radio"
                        className="btn-check"
                        name="declareOption"
                        id="success-outlined"
                        // defaultChecked
                        checked={declareFit}
                        onChange={() => setDeclareFit(true)}
                      />
                      <Label className="btn btn-outline-success" for="success-outlined">
                        Declare All Fit
                      </Label>
                      &nbsp;&nbsp;&nbsp;
                      <Input
                        type="radio"
                        className="btn-check"
                        name="declareOption"
                        id="danger-outlined"
                        checked={!declareFit}
                        onChange={() => setDeclareFit(false)}
                      />
                      <Label className="btn btn-outline-danger" for="danger-outlined">
                        Declare Not Fit
                      </Label>
                    </div>
                  </FormGroup>

                  <Row className="mt-4">
                    <Col md={12}>
                      <Table className="table-borderless align-middle mb-0">
                        <thead className="table-light">
                          <tr>
                            {!declareFit && (
                              <th scope="col">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => handleSelectAll(e.target.checked)}
                                  checked={
                                    selectedEmployees.length ===
                                    (toolboxMeetingByIdData?.toolbox_attendance?.length || 0)
                                  }
                                />
                              </th>
                            )}
                            <th scope="col">Employee No</th>
                            <th scope="col">NRIC/FIN</th>
                            <th scope="col">Name</th>
                            <th scope="col">Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {toolboxMeetingByIdData?.toolbox_attendance?.length > 0 ? (
                            toolboxMeetingByIdData.toolbox_attendance.map((attendance: any, index: number) => (
                              <tr key={index}>
                                {!declareFit && (
                                  <td>
                                    <Input
                                      type="checkbox"
                                      onChange={() => handleEmployeeSelection(attendance.employee.id)}
                                      //@ts-ignore
                                      checked={selectedEmployees.includes(attendance.employee.id)}
                                    />
                                  </td>
                                )}
                                <td>{attendance?.employee?.employee_number}</td>
                                <td>{attendance?.employee?.nric_mask}</td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {attendance?.employee.employee_profile_url ? (
                                      <div className="flex-shrink-0">
                                        <img
                                          src={attendance?.employee.employee_profile_url}
                                          alt=""
                                          className="avatar-xs rounded-circle"
                                        />
                                      </div>
                                    ) : (
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                          {attendance?.employee.employee_fullname.charAt(0)}
                                        </div>
                                      </div>
                                    )}
                                    <div className="flex-grow-1 ms-2">
                                      <h6 className="mb-1">{attendance?.employee.employee_fullname}</h6>
                                    </div>
                                  </div>
                                </td>
                                <td>{attendance?.employee.position_name}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} className="text-center">
                                No employee attendance added yet
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      <FormGroup className="mt-3">
                        <Label for="approvalComment">Add Declaration Comment</Label>
                        <span className="text-danger">*</span>
                        <Input
                          required
                          type="textarea"
                          name="approvalComment"
                          id="approvalComment"
                          placeholder="Add Declaration Comment"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        />
                        {!reason && (<div className="text-danger mt-1">Comment is required</div>)}
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <h5 className="text-center mb-3">Preview Declaration</h5>
                  <p>
                    You are about to submit the following health declaration for Toolbox Meeting{" "}
                    <b>{toolboxMeetingByIdData?.toolbox_id}</b>.
                  </p>

                  <Table className="table-borderless align-middle mb-0" bordered>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Employee No</th>
                        <th scope="col">NRIC/FIN</th>
                        <th scope="col">Name</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Health Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {toolboxMeetingByIdData?.toolbox_attendance?.map((attendance: any, index: number) => {
                        //@ts-ignore
                        const isNotFit = !declareFit && selectedEmployees.includes(attendance.employee.id);
                        const healthStatus = declareFit
                          ? "Fit"
                          : isNotFit
                            ? "Not Fit"
                            : "Fit";

                        return (
                          <tr key={index}>
                            <td>{attendance?.employee?.employee_number}</td>
                            <td>{attendance?.employee?.nric_mask}</td>
                            <td>{attendance?.employee?.employee_fullname}</td>
                            <td>{attendance?.employee?.position_name}</td>
                            <td>
                              {
                                healthStatus === 'Fit' ?
                                  <Badge color="success" className="fs-12 text-uppercase">{healthStatus}</Badge> :
                                  <Badge color="danger" className="fs-12 text-uppercase">{healthStatus}</Badge>
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <FormGroup className="mt-3">
                    <Label for="approvalComment">Declaration Comment: {reason}</Label>
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {!showPreview ? (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <Button color="primary" onClick={() => setShowPreview(true)} disabled={(!reason || (declareFit === false && selectedEmployees.length === 0))}>
                    Preview
                  </Button>
                  <Button color="secondary" onClick={handleOpenHealthDeclaration} disabled={healthDeclarationLoading}>
                    Cancel
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <Button color="secondary" onClick={() => setShowPreview(false)} disabled={healthDeclarationLoading || isFetching}>
                    Back
                  </Button>
                  <Button type="submit" color="success" disabled={healthDeclarationLoading || isFetching}>
                    {healthDeclarationLoading || isFetching ? <Spinner size="sm" /> : "Submit"}
                  </Button>
                </div>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        <ValidationAlertStatic
          modalBackDrop={modalBackDrop}
          setModalBackDrop={setModalBackDrop}
          validationMessages={validationMessages}
          previousPageNavLink="/homeworkpermit-list"
        />

      </div>
    </React.Fragment>
  );
};

export default Summary;
