import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import {
  useGetLiftingWorkQuestionnaireKeysQuery,
  useCreateLiftingWorkQuestionnaireKeyMutation,
  useUpdateLiftingWorkQuestionnaireKeyByIdMutation,
  useDeleteQuestionnaireLiftingWorkPermitMutation,
} from "slices/safetyApp/safetyappSlice";

interface QuestionData {
  id?: number;
  question: string;
}

interface QuestionModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSave: (data: QuestionData) => void;
  initialData?: QuestionData | null;
  suggestions: string[];
  loading: boolean;
}

const QuestionModal: React.FC<QuestionModalProps> = ({ isOpen, toggle, onSave, initialData, suggestions, loading }) => {
  const [question, setQuestion] = useState<string>(initialData?.question || "");
  
const handleCancelQuestion = () => {
    setQuestion('');
    toggle();
  };

  useEffect(() => {
    setQuestion(initialData?.question || "");
  }, [initialData]);

  const handleSave = () => {
    if (question.trim()) {
      onSave({ ...initialData, question });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{initialData ? "Update" : "Create"} Question</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="question">Question</Label>
          <Input
            type="textarea"
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            autoComplete="off"
            disabled={loading}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave} disabled={loading || question.trim() === ""}>
          {loading ? <Spinner size="sm" /> : "Save"}
        </Button>
        <Button color="secondary" onClick={handleCancelQuestion} disabled={loading}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const LiftingWorkQuestionnaireTab: React.FC = () => {
  const { data: questionsData, refetch } = useGetLiftingWorkQuestionnaireKeysQuery(undefined);
  const [createQuestion] = useCreateLiftingWorkQuestionnaireKeyMutation();
  const [updateQuestion] = useUpdateLiftingWorkQuestionnaireKeyByIdMutation();
  const [deleteQuestion] = useDeleteQuestionnaireLiftingWorkPermitMutation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionData | null>(null);
  const [deleteId, setDeleteId] = useState<number | null>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleDeleteModal = () => setDeleteModalOpen(!deleteModalOpen);

  const handleAddQuestion = () => {
    setCurrentQuestion(null);
    toggleModal();
  };

  const handleEditQuestion = async (question: QuestionData) => {
    setCurrentQuestion(question);
    toggleModal();
  };

  const handleSaveQuestion = async (questionData: QuestionData) => {
    setLoading(true);
    try {
      if (questionData.id) {
        const id = questionData.id;
        const updatedQuestionnaireKeyData = questionData;
        await updateQuestion({ id, updatedQuestionnaireKeyData }).unwrap();
      } else {
        await createQuestion(questionData).unwrap();
      }
      refetch();
      toggleModal();
    } catch (error) {
      console.error("Error saving question:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteQuestion = async () => {
    if (deleteId) {
      setLoading(true);
      try {
        await deleteQuestion({id:deleteId}).unwrap();
        refetch();
        toggleDeleteModal();
      } catch (error) {
        console.error("Error deleting question:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const suggestions = questionsData ? questionsData.map((q: QuestionData) => q.question) : [];

  const filteredQuestions = questionsData?.filter((q: QuestionData) =>
    q.question.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">Lifting Work Permit Questionnaire</h5>
          <Input
            type="text"
            placeholder="Search questions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="me-2"
            style={{ width: "300px" }}
          />
          <Button color="primary" onClick={handleAddQuestion}>Add Question</Button>
        </div>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Question</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredQuestions.length > 0 ? (
              filteredQuestions.map((q: QuestionData, index: number) => (
                <tr key={q.id}>
                  <td>{index + 1}</td>
                  <td>{q.question}</td>
                  <td>
                    <Button color="warning" size="sm" onClick={() => handleEditQuestion(q)}>Edit</Button>{" "}
                  </td>
                  <td>
                    <Button color="danger" size="sm" onClick={() => {setDeleteId(q?.id); toggleDeleteModal(); }}>Delete</Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center">No Questions Found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <QuestionModal
          isOpen={modalOpen}
          toggle={toggleModal}
          onSave={handleSaveQuestion}
          initialData={currentQuestion}
          suggestions={suggestions}
          loading={loading}
        />
        <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
          <ModalBody>Are you sure you want to delete this question?</ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={handleDeleteQuestion} disabled={loading}>Delete</Button>
              <Button color="secondary" onClick={toggleDeleteModal} disabled={loading}>Cancel</Button>
            </ModalFooter>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default LiftingWorkQuestionnaireTab;
