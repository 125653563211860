import React from 'react';
import { projectsWidgets } from '../../common/data';
import CountUp from "react-countup";
import {
    useGetDashboardSummaryQuery
  } from "slices/dashboardProject/dashboardSlice";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { Card, CardBody, Col, Row } from 'reactstrap';

const EmployeeWidgets = () => {

    const {
        data: summaryList,
        // isLoading: isSummaryLoading,
        // refetch: refetchSummary,
        // error: summaryError,
      } = useGetDashboardSummaryQuery(undefined);

      console.log('summaryList', summaryList)

    return (
        <React.Fragment>
            <Row>

                    {/* <Col xl={4} >
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={`avatar-title bg-danger rounded-2 fs-2`}>
                                            <FeatherIcon icon={"briefcase"} />
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden ms-3">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-3">{"Members"}</p>
                                        <div className="d-flex align-items-center mb-3">
                                            <h4 className="fs-4 flex-grow-1 mb-0">
                                                <span className="counter-value me-1" data-target="825" >
                                                    <CountUp
                                                        start={0}
                                                        suffix={""}
                                                        separator={","}
                                                        end={summaryList?.employee_count}
                                                        duration={2}
                                                    />
                                                </span>
                                            </h4>
                                        </div>
                                        <p className="text-muted text-truncate mb-0">{"No of Active Members"}</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col> */}

                    <Col xl={6} >
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={`avatar-title bg-danger rounded-2 fs-2`}>
                                            <FeatherIcon icon={"briefcase"} />
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden ms-3">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-3">{"Projects"}</p>
                                        <div className="d-flex align-items-center mb-3">
                                            <h4 className="fs-4 flex-grow-1 mb-0">
                                                <span className="counter-value me-1" data-target="825" >
                                                    <CountUp
                                                        start={0}
                                                        suffix={""}
                                                        separator={","}
                                                        end={summaryList?.project_count}
                                                        duration={2}
                                                    />
                                                </span>
                                            </h4>
                                            {/* <span className={"fs-12 badge bg-" + "danger" +"-subtle text-"+ "danger"}><i className={"fs-13 align-middle me-1 " + "ri-arrow-down-s-line"}></i>{"53 %"}</span> */}
                                        </div>
                                        <p className="text-muted text-truncate mb-0">{"No of Active Projects"}</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl={6} >
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={`avatar-title bg-danger rounded-2 fs-2`}>
                                            <FeatherIcon icon={"briefcase"} />
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden ms-3">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-3">{"Active Reports"}</p>
                                        <div className="d-flex align-items-center mb-3">
                                            <h4 className="fs-4 flex-grow-1 mb-0">
                                                <span className="counter-value me-1" data-target="825" >
                                                    <CountUp
                                                        start={0}
                                                        suffix={""}
                                                        separator={","}
                                                        end={summaryList?.report_summary_count}
                                                        duration={2}
                                                    />
                                                    {/* <CountUp
                                                        start={0}
                                                        suffix={""}
                                                        separator={","}
                                                        end={
                                                            Number(summaryList?.task_count?.find((n: any) => Object.keys(n)[0] === "Total") 
                                                            ? Object.values(summaryList.task_count.find((n: any) => Object.keys(n)[0] === "Total"))[0] : 0) 
                                                        }
                                                        duration={2}
                                                    /> */}
                                                </span>
                                            </h4>
                                        </div>
                                        <p className="text-muted text-truncate mb-0">{"No of Open Reports"}</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

            </Row>
        </React.Fragment>
    );
};

export default EmployeeWidgets;