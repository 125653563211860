import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import {
    useGetUserNotificationQuery
  } from "slices/notification/NotificationSlice";

//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = () => {
    const [isNotificationDropdown, setIsNotificationDropdown] = useState<boolean>(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    const [page, setPageState] = useState<{
        page: number,
        pageSize: number,
        isRead: boolean,
    }>({
        page: 1,
        pageSize: 20,
        isRead: false,
    });

    const {
        data: allNotification,
        isLoading: isNotificationLoading,
        isFetching: isNotificationFetching,
        refetch: refetchNotification,
        error: errorNotification,
    } = useGetUserNotificationQuery(page);

    console.log('allNotification', allNotification);

    // Tab state
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    // Filtered notifications
    const selfNotifications = allNotification?.filter((item: any) => item.message_type === "Self") || [];
    const generalNotifications = allNotification?.filter((item: any) => item.message_type === "General") || [];

    const renderNotificationItem = (notification: any) => (
        <div key={notification.id} className="text-reset notification-item d-block dropdown-item position-relative">
            <div className="d-flex">
                {notification.employee_profile_url?
                    <div className="flex-shrink-0">
                        <img src={notification.employee_profile_url} alt="" className="me-3 avatar-xs rounded-circle" />
                    </div> :
                    <div className="flex-shrink-0 avatar-xs">
                        <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                        {notification.employee_fullname.charAt(0)}
                        </div>
                </div>}
                {/* <img src={notification.employee_profile_url} className="me-3 rounded-circle avatar-xs" alt="user-pic" /> */}
                <div className="flex-grow-1">
                    <Link to="#" className="stretched-link">
                        <h6 className="mt-0 mb-2 lh-base">{notification.message}</h6>
                    </Link>
                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span><i className="mdi mdi-clock-outline"></i> {notification.created_at_text}</span>
                    </p>
                </div>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                    <i className='bx bx-bell fs-22'></i>
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                        {selfNotifications.length + generalNotifications.length}
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0" style={{width: "500px"}}>
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge bg-light-subtle text-body fs-13">
                                        {selfNotifications.length} New
                                    </span>
                                </div>
                            </Row>
                        </div>

                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        You ({selfNotifications.length})
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        General ({generalNotifications.length})
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {selfNotifications.map(renderNotificationItem)}
                                <div className="my-3 text-center">
                                    <button type="button" className="btn btn-soft-success waves-effect waves-light">
                                        View All Notifications <i className="ri-arrow-right-line align-middle"></i>
                                    </button>
                                </div>
                            </SimpleBar>
                        </TabPane>

                        <TabPane tabId="2" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {generalNotifications.map(renderNotificationItem)}
                                <div className="my-3 text-center">
                                    <button type="button" className="btn btn-soft-success waves-effect waves-light">
                                        View All Messages <i className="ri-arrow-right-line align-middle"></i>
                                    </button>
                                </div>
                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;
