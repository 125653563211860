import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Button,
  UncontrolledDropdown,
} from "reactstrap";
import { getTextColor, STATUS_COLORS } from "helpers/common_helper";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { ToastContainer } from "react-toastify";
import Loader from "Components/Common/Loader";
import TableContainer from "Components/Common/TableContainer";
import { BadgeStatus } from "helpers/badgeStatus";
import { Tooltip } from 'react-tooltip';

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Icons
import FeatherIcon from "feather-icons-react";

//import action
import {
  getProjectList as onGetProjectList,
  deleteProjectList as onDeleteProjectList,
} from "../../../slices/thunks";
import { createSelector } from "reselect";

import {
  useGetAllProjectsByUserRoleQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from "slices/projects/projectSlice";
import { ProjectFormModal } from "../Modal/ProjectForm";
import ExportCSVModal from "Components/Common/ExportCSVModal";

const List = () => {
  const dispatch: any = useDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<"card" | "table">("card");
  // const selectDashboardData = createSelector(
  //   (state: any) => state.Projects.projectLists,
  //   (projectLists) => projectLists
  // );
  // const projectLists = useSelector(selectDashboardData);

  const [project, setProject] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
  const itemsPerPage = 8;

  const [page, setPageState] = useState<{
    page: number,
    pageSize: number,
    search: string,
    status_name: string,
    start_date: string,
    due_date: string
  }>({
    page: 1,
    pageSize: 10,
    search: '',
    status_name: '',
    start_date: '',
    due_date: '',
  });

  const {
    data: allProjects,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
    refetch: refetchProject,
    error: projectError,
  } = useGetAllProjectsByUserRoleQuery(page);
  const [createProject, { isLoading: isLoadingCreateProject }] =
    useCreateProjectMutation();
  const [updateProject, { isLoading: isLoadingUpdateProject }] =
    useUpdateProjectMutation();

  // const toggle = useCallback(() => {
  //   if (modal) {
  //     // Closing the modal
  //     setModal(false);
  //     setProject(null);
  //   } else {
  //     setModal(true);
  //   }
  // }, [modal]);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    dispatch(onGetProjectList());
  }, [dispatch]);

  // useEffect(() => {
  //   setProject(projectLists);
  // }, [projectLists]);

  const onClickData = (project: any) => {
    setProject(project);
    setDeleteModal(true);
  };

  const handleDeleteProjectList = () => {
    if (project) {
      dispatch(onDeleteProjectList(project.id));
      setDeleteModal(false);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Project #",
        accessor: "project_id",
        filterable: false,
      },
      {
        Header: "Project Code",
        accessor: "project_short_name",
        filterable: false,
      },
      {
        Header: "Project",
        accessor: "project_name",
        filterable: false,
      },

      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "status_name",
        filterable: false,
        Cell: (cell: any) => (
          <>
            <span className="badge bg-primary-subtle text-primary me-1">
              {cell.row.original?.status_name}
            </span>
          </>
        ),
      },
      // {
      //   Header: "Estimation Cost",
      //   accessor: "project_estimation_cost",
      //   filterable: false,
      // },
      // {
      //   Header: "Allocated Budget",
      //   accessor: "project_allocated_budget",
      //   filterable: false,
      // },
      // {
      //   Header: "Due Date",
      //   accessor: "end_date",
      //   filterable: false,
      // },
      {
        Header: "Actions",
        accessor: "actions",
        filterable: false,
        Cell: ({ row }: any) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                tag="button"
                className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
              >
                <FeatherIcon icon="more-horizontal" className="icon-sm" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href={`/apps-projects-overview/${row?.original?.id}`}
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                  View
                </DropdownItem>
                <Link
                  className="text-black"
                  to={`/inventory-dashboard/${row?.original?.id}`}
                >
                  <DropdownItem>
                    <i className="ri-file-fill align-bottom me-2 text-muted"></i>{" "}
                    Inventory
                  </DropdownItem>
                </Link>
                <DropdownItem
                  href="#"
                  onClick={() => {
                    setProject(row?.original); // Pass the current project to the modal
                    setIsEdit(true); // Set edit mode to true
                    toggle(); // Open the modal
                  }}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                  Edit
                </DropdownItem>
                <div className="dropdown-divider"></div>
                {/* <DropdownItem
                  href="#"
                  onClick={() => onClickData(row?.original)}
                  data-bs-toggle="modal"
                  data-bs-target="#removeProjectModal"
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                  Remove
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  const indexOfLastProject = currentPage * itemsPerPage;
  const indexOfFirstProject = indexOfLastProject - itemsPerPage;
  console.log('allProjects', allProjects)
  // const currentProjects = allProjects?.slice(
  //   indexOfFirstProject,
  //   indexOfLastProject
  // );

  const currentProjects =  allProjects?.results || [];

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const totalPages = allProjects?.total_page  //Math.ceil(allProjects?.length / itemsPerPage);

  const activebtn = (ele: any) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  return (
    <React.Fragment>
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".start-date-class" content="Project Start Date" />
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".end-date-class" content="Project End Date" />
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteProjectList()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Row className="g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            <Button
              onClick={() => {
                setIsEdit(false);
                setProject({});
                toggle();
              }}
              disabled={isProjectLoading}
              className="btn btn-success"
            >
              <i className="ri-add-line align-bottom me-1"></i> New Project
            </Button>
          </div>
        </div>
        <div className="col-sm-3 ms-auto">
          {/* <div className="d-flex justify-content-sm-end gap-2">
            <div className="search-box ms-2 col-sm-7">
              <Input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <i className="ri-search-line search-icon"></i>
            </div>

            <select
              className="form-control w-md"
              data-choices
              data-choices-search-false
            >
              <option value="All">All</option>
              <option value="Last 7 Days">Last 7 Days</option>
              <option value="Last 30 Days">Last 30 Days</option>
              <option value="Last Year">Last Year</option>
              <option value="This Month">This Month</option>
              <option value="Today">Today</option>
              <option value="Yesterday" defaultValue="">
                Yesterday
              </option>
            </select>
          </div> */}
        </div>

        <div className="col-sm-auto">
          <Button
            onClick={() => setViewMode(viewMode === "card" ? "table" : "card")}
            className="btn btn-primary"
          >
            {viewMode === "card" ? (
              <i className="ri ri-table-2"></i>
            ) : (
              <i className="ri ri-grid-line"></i>
            )}
          </Button>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsExportCSV(true)}
            disabled={isProjectLoading || allProjects?.length === 0}
          >
            <i className="ri-file-download-line align-bottom me-1"></i> Export
          </button>
        </div>
      </Row>

      {viewMode === "card" ? (
        <div>
          {!isProjectFetching ?
          <div className="row">
            {currentProjects.map((item: any, index: any) => (
              <React.Fragment key={index}>
                <Col xxl={3} sm={6} className="project-card">
                  <Card className="card-height-100">
                    <CardBody>
                      <div className="d-flex flex-column h-100">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            {/* <p className="text-muted mb-4">Updated 3hrs ago</p> */}
                          </div>

                          <div className="flex-shrink-0">
                            <div className="d-flex gap-1 align-items-center">
                              {/* <button
                                type="button"
                                className={`btn avatar-xs mt-n1 p-0 favourite-btn shadow-none active`}
                                onClick={(e) => activebtn(e.target)}
                              >
                                <span className="avatar-title bg-transparent fs-15">
                                  <i className="ri-star-fill"></i>
                                </span>
                              </button> */}
                              <UncontrolledDropdown direction="start">
                                <DropdownToggle
                                  tag="button"
                                  className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                                >
                                  <FeatherIcon
                                    icon="more-horizontal"
                                    className="icon-sm"
                                  />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    href={`/apps-projects-overview/${item.id}`}
                                  >
                                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                                    View
                                  </DropdownItem>
                                  {/* <Link
                                    className="text-black"
                                    to={`/inventory-dashboard/${item.id}`}
                                  >
                                    <DropdownItem>
                                      <i className="ri-file-fill align-bottom me-2 text-muted"></i>{" "}
                                      Inventory
                                    </DropdownItem>
                                  </Link> */}
                                  <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      setProject(item);
                                      setIsEdit(true);
                                      toggle();
                                    }}
                                  >
                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                    Edit
                                  </DropdownItem>
                                  {/* <div className="dropdown-divider"></div> */}
                                  {/* <DropdownItem
                                    href="#"
                                    onClick={() => onClickData(item)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#removeProjectModal"
                                  >
                                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                    Remove
                                  </DropdownItem> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-2">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <span
                                className={
                                  "avatar-title rounded-circle p-2 bg-" +
                                  item.imgbgColor +
                                  "-subtle"
                                }
                                style={{ fontSize: "22px" }}
                              >
                                {item?.thumbnail_url ? (
                                  <img
                                    src={item.thumbnail_url}
                                    alt=""
                                    className="rounded-circle avatar-sm"
                                  />
                                ) : (
                                  <>{item.project_name.charAt(0)}</>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="mb-1 fs-15">
                              <Link
                                to={`/apps-projects-overview/${item.id}`}
                                className="text-body"
                              >
                                {item?.project_name}
                              </Link>
                            </h5>
                            <h6 className="mt-3 fs-14">
                              <span className="text-muted text-uppercase">PROJECT CODE: </span>{" "}{item?.project_short_name}
                            </h6>
                            <p className="text-muted text-truncate-two-lines mt-2 mb-3 fs-14">
                              <span className="text-muted">DESCRIPTION: </span>{" "}{item?.description}
                            </p>
                          </div>
                        </div>
                        {/* <div className="mt-auto">
                          <div className="d-flex mb-2">
                            <div className="flex-grow-1">
                              <div className="text-muted mb-2">
                                Actual Cost: {item?.project_actual_cost || 0}
                              </div>
                              <div className="text-muted mb-2">
                                Estimation Cost:{" "}
                                {item?.project_estimation_cost || 0}
                              </div>
                              <div className="text-muted">
                                Allocated Budget:{" "}
                                {item?.project_allocated_budget || 0}
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </CardBody>
                    <div className="card-footer bg-transparent border-top-dashed py-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="avatar-group fs-6" style={{ padding: 0 }}>
                            <BadgeStatus value={item?.status_name} color={STATUS_COLORS[item?.status_name]} />

                            {/* {item?.subItem?.map((item : any, key : any) => (
                                                       <React.Fragment key={key}>
                                                           {item.imgFooter ? <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Darline Williams">
                                                               <div className="avatar-xxs">
                                                                   <img src={item.imgFooter} alt="" className="rounded-circle img-fluid" />
                                                               </div>
                                                           </Link> : <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Add Members">
                                                               <div className="avatar-xxs">
                                                                   <div className={item.bgColor ? "avatar-title rounded-circle bg-" + item.bgColor : "avatar-title rounded-circle bg-light border-dashed border text-primary fs-16 "}>
                                                                       {item.imgNumber}
                                                                   </div>
                                                               </div>
                                                           </Link>}
                                                       </React.Fragment>
                                                   ))} */}
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          {item?.start_date ?
                            <span className="text-muted start-date-class">
                              <i className="ri-calendar-event-fill me-1 align-bottom"></i> {item?.start_date}
                              &nbsp; &nbsp; &nbsp;
                            </span>

                            : ''}

                          {item?.end_date ? (
                            <span className="text-muted end-date-class">
                              <i className="ri-calendar-event-fill me-1 align-bottom"></i>{" "}
                              {item?.end_date}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </React.Fragment>
            ))}
          </div>
          : <></>}
          <Row className="g-0 text-center text-sm-start align-items-center mb-4">
            <Col sm={6}>
              <div>
                <p className="mb-sm-0 text-muted">
                  Showing Page <span className="fw-semibold">{currentPage}</span> 
                  {/* to{" "} <span className="fw-semibold">{allProjects?.length}</span>  */}
                  {" "} of {" "} <span className="fw-semibold text-decoration-underline">
                  {allProjects?.total_page}
                    {/* {totalPages} */}
                  </span>{" "} 
                  {/* entries */}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() =>
                      setPageState((prev: any) => ({
                        ...prev,
                        page: Number(allProjects?.current_page) - 1
                      }))
                    }
                    disabled={allProjects?.current_page === 1}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${allProjects?.current_page === index + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setPageState((prev: any) => ({
                        ...prev,
                        page: index + 1
                      }))}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() =>
                      // setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      setPageState((prev: any) => ({
                        ...prev,
                        page: Number(allProjects?.current_page) + 1
                      }))
                    }
                    disabled={allProjects?.current_page === allProjects?.total_page}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="card" id="projectList">
              <div className="card-body pt-0">
                <TableContainer
                  columns={columns}
                  data={allProjects?.results || []}
                  isLoading={isProjectFetching}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={page.pageSize}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light text-muted"
                  thClass="table-light text-muted"
                  // handleTaskClick={handleTaskClicks}
                  // isTaskListFilter={true}
                  SearchPlaceholder="Search for Projects..."
                  isPagination={false}
                  isServerPagination={true}
                  serverPaginationProps={{
                    count: allProjects?.count || 0,
                    next: allProjects?.next || null,
                    page_size: allProjects?.page_size || 10,
                    previous: allProjects?.previous || null,
                    current_page: allProjects?.current_page || 1,
                    total_page: allProjects?.total_page || 0
                  }}
                  setPageState={setPageState}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      {/* <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={allProjects || []}
        columns={columns}
        name={"Projects"}
      /> */}

      {project && (
        <ProjectFormModal
          modal={modal}
          toggle={toggle}
          isEdit={isEdit}
          isLoadingCreateTask={
            !isEdit ? isLoadingCreateProject : isLoadingUpdateProject
          }
          selectedFiles={selectedFiles}
          setselectedFiles={setselectedFiles}
          setModal={setModal}
          project={project}
          createProject={createProject}
          updateProject={updateProject}
          refetchProject={refetchProject}
        />
      )}

      {isProjectLoading || isLoadingCreateProject || isLoadingUpdateProject ? (
        <Loader />
      ) : null}
    </React.Fragment>
  );
};

export default List;
