import React from 'react';
import { Spinner } from 'reactstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loader = (props: any) => {
    return (
        <React.Fragment>
            <div className="d-flex flex-column justify-content-center align-items-center mx-2 mt-5">
                <Spinner color="primary" />
                <div className="mt-2">Loading...</div>
            </div>
            {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}
        </React.Fragment>
    );
};

export default Loader;
