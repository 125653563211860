import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import Step1HotWorkPermit from "./WizardSteps/Step1HotWorkPermit";
import Step2QuestionnaireIndex from "./WizardSteps/Step2QuestionnaireIndex";
import Step3WorkAreaAllocationIndex from "./WizardSteps/Step3WorkAreaAllocationIndex";
import Step4ToolBoxMeetingIndex from "./WizardSteps/Step4ToolBoxMeetingIndex";
import Step5FileUploadIndex from "./WizardSteps/Step5FileUploadIndex";
import Step6PreviewSubmitIndex from "./WizardSteps/Step6PreviewSubmitIndex";
import { safetyAppSlice } from "slices/safetyApp/safetyappSlice";
import ValidationAlertStatic from "../../ValidationAlertStatic";
import { getErrorText } from "../../../../helpers/error_message";
import { useGetWorkflowsQuery, 
  useCheckSafetyAppAssignedWorkflowMutation,
  useCheckSafetyAppReportStatusMutation
 } from "slices/workflow/WorkFlowSlice";
import { useGetProjectsByAssignedUserQuery } from "slices/projects/projectSlice";


const HotworkPermitWizard: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<number>(1);
  

  const [modalBackDrop, setModalBackDrop] = useState<boolean>(false);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);

  const [location, setLocation] = useState<{ latitude: number | null; longitude: number | null }>({ latitude: null, longitude: null });

  const { selectedProject, hwPermitNo: hwPermitNoFromURL } = useParams<{ selectedProject: string; hwPermitNo?: string }>();
  const { data: projectsData } = useGetProjectsByAssignedUserQuery(undefined);

  const [checkSafteyAppAssignedWorkflow] = useCheckSafetyAppAssignedWorkflowMutation();
  const [checkSafetyAppReportStatus] = useCheckSafetyAppReportStatusMutation();

  const projectFilterDataVal = projectsData?.filter((n: any) => String(n.id) === selectedProject);
  const projectFilterData = projectFilterDataVal?.length > 0 ? projectFilterDataVal[0] : {} 

  const {
    data: workFlow,
    isLoading: isLoadingWorkFlow,
    // refetch: refetchWorkFlow,
  } = useGetWorkflowsQuery("SAFETY_APP");

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      if (hwPermitNoFromURL) {
        dispatch(safetyAppSlice.util.invalidateTags([{ type: "GetHotworkPermitByNumber", id: hwPermitNoFromURL }]));
      }
      setActiveTab(tab);
    }
  };


  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (err) => {
          setValidationMessages((prev) => {
            const errorMessage = getErrorText('location-disabled');
            return prev.includes(errorMessage)
              ? prev
              : [...prev, errorMessage]
          });
          setModalBackDrop(true);
        },
        { enableHighAccuracy: true }
      );
    } else {
      setValidationMessages((prev) => {
        const errorMessage = getErrorText('location-not-supported');
        return prev.includes(errorMessage)
          ? prev
          : [...prev, errorMessage]
      });
      setModalBackDrop(true);

    }
  };

  const checkWorkflowProject = async () => {
    let assignedWorkflow: any = await checkSafteyAppAssignedWorkflow({ reportKey: 'HOT_WORK_PERMIT', projectId: selectedProject });
    
    if (assignedWorkflow.data.length === 0) {
      setValidationMessages((prev: any) => {
        // Use a unique identifier to avoid duplicates
        const errorMessageText = `${getErrorText('workflow-not-assigned')} ${projectFilterData?.project_name}`;
        if (prev.some((message: any) => message.key === errorMessageText)) {
          return prev; // If already exists, return the same list
        }
  
        // Add new error message
        const errorMessage = (
          <span key={errorMessageText}>
            {getErrorText('workflow-not-assigned')} <b>{projectFilterData?.project_name}</b>
          </span>
        );
        return [...prev, errorMessage];
      });
  
      setModalBackDrop(true);
    }
  };

  const checkProjectStatus = async () => {
    let assignedReport: any = await checkSafetyAppReportStatus({
      reportKey: 'HOT_WORK_PERMIT',
      projectId: selectedProject,
    });
  
    if (assignedReport.data.length !== 0) {
      setValidationMessages((prev: any) => {
        const existingKeys = new Set(prev.map((message: any) => message.key)); // Track existing keys
        const newMessages: any[] = [];
  
        assignedReport.data.forEach((report: any) => {
          const permitNo = report.permit_no;
          const permitStatus = report.report_status;
  
          // Generate a unique key for this report
          const errorMessageKey = `${permitNo}-${permitStatus}`;
          
          // Add error message only if the key doesn't already exist
          if (
            !existingKeys.has(errorMessageKey) &&
            (hwPermitNoFromURL !== permitNo) //&&
            // (permitStatus !== 'Draft' && permitStatus !== 'Resubmission')
          ) {
            const errorMessage = (
              <span key={errorMessageKey}>
                {getErrorText('report-status-exist')} <b>{permitNo} - {permitStatus}</b>
              </span>
            );
            newMessages.push(errorMessage);
          }
        });
  
        // If there are new messages, show the modal
        if (newMessages.length > 0) {
          setModalBackDrop(true);
        }
  
        // Return the combined array of previous and new messages
        return [...prev, ...newMessages];
      });
    }
  };
  

  useEffect(() => {
    getLocation();
    setValidationMessages([]);
    checkWorkflowProject();
    checkProjectStatus();
  }, [selectedProject, projectsData]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Hot Work Permit" breadcrumbItem="Wizard" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody className="form-steps">
                <div className="step-arrow-nav mb-4">
                  <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 1 })}>
                        Permit Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 2 })}>
                        Check List
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 3 })}>
                        Work Area Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 4 })}>
                        ToolBox Meeting
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 5 })}>
                        File Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 6 })}>
                        Preview & Submit
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Step1HotWorkPermit selectedProject={selectedProject!} projectsData={projectFilterData} nextStep={() => toggleTab(2)} />
                  </TabPane>
                  <TabPane tabId={2}>
                    <Step2QuestionnaireIndex previousStep={() => toggleTab(1)} nextStep={() => toggleTab(3)} />
                  </TabPane>
                  <TabPane tabId={3}>
                    <Step3WorkAreaAllocationIndex previousStep={() => toggleTab(2)} nextStep={() => toggleTab(4)} />
                  </TabPane>
                  <TabPane tabId={4}>
                    <Step4ToolBoxMeetingIndex previousStep={() => toggleTab(3)} nextStep={() => toggleTab(5)} />
                  </TabPane>
                  <TabPane tabId={5}>
                    <Step5FileUploadIndex previousStep={() => toggleTab(4)} nextStep={() => toggleTab(6)} />
                  </TabPane>
                  <TabPane tabId={6}>
                    <Step6PreviewSubmitIndex
                      location={location}
                      previousStep={() => toggleTab(5)}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ValidationAlertStatic
        modalBackDrop={modalBackDrop}
        setModalBackDrop={setModalBackDrop}
        validationMessages={validationMessages}
        previousPageNavLink="/hotworkpermit-list"
      />
    </div>
  );
};

export default HotworkPermitWizard;
