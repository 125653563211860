import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import ConfinedSpaceQuestionnaireTab from "./PermitQuestionnaire/ConfinedSpacePermitQuestionnaire";
import LiftingPermitQuestionnaireTab from "./PermitQuestionnaire/LiftingPermitQuestionnaire";
import WorkAtHeightQuestionnaireTab from "./PermitQuestionnaire/WorkAtHeightPermitQuestionnaire";
import GeneralworkQuestionnaireTab from "./PermitQuestionnaire/GeneralWorkPermitQuestionnaire";
import HotworkQuestionnaireTab from "./PermitQuestionnaire/HotWorkPermitQuestionnarie";
import ExcavationPermitQuestionnaireTab from "./PermitQuestionnaire/ExcavationPermitQuestionnaire";

const QuestionnarieTab = () => {

  const [verticalTab, setVerticalTab] = useState("HotWork Permit");

  const toggleVertical = (tab: any) => {
    if (verticalTab !== tab) {
      setVerticalTab(tab);
    }
  };
  
  return (
    <React.Fragment>
      <Card>
        <CardBody>

          <Row>
            <Col md={3}>
              <Nav pills className="flex-column" id="v-pills-tab">
                {/* Default "ALL" Tab */}


                {/* Dynamically Render Team Tabs */}
               
                    <NavItem key={1}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ "mb-2": true, active: verticalTab === 'HotWork Permit' })}
                        onClick={() => toggleVertical('HotWork Permit')}
                        id={`v-pills-${'HotWork Permit'.toLowerCase()}-tab`}
                      >
                       HotWork Permit
                      </NavLink>
                    </NavItem>
                    
                    <NavItem key={2}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ "mb-2": true, active: verticalTab === 'GeneralWork Permit' })}
                        onClick={() => toggleVertical('GeneralWork Permit')}
                        id={`v-pills-${'GeneralWork Permit'.toLowerCase()}-tab`}
                      >
                       GeneralWork Permit
                      </NavLink>
                    </NavItem>
                    
                    <NavItem key={3}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ "mb-2": true, active: verticalTab === 'WorkAtHeight Permit' })}
                        onClick={() => toggleVertical('WorkAtHeight Permit')}
                        id={`v-pills-${'WorkAtHeight Permit'.toLowerCase()}-tab`}
                      >
                       WorkAtHeight Permit
                      </NavLink>
                    </NavItem>
                    
                    <NavItem key={4}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ "mb-2": true, active: verticalTab === 'ConfinedSpace Permit' })}
                        onClick={() => toggleVertical('ConfinedSpace Permit')}
                        id={`v-pills-${'ConfinedSpace Permit'.toLowerCase()}-tab`}
                      >
                       ConfinedSpace Permit
                      </NavLink>
                    </NavItem>
                    
                    <NavItem key={5}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ "mb-2": true, active: verticalTab === 'Lifting Permit' })}
                        onClick={() => toggleVertical('Lifting Permit')}
                        id={`v-pills-${'Lifting Permit'.toLowerCase()}-tab`}
                      >
                       Lifting Permit
                      </NavLink>
                    </NavItem>
                    <NavItem key={6}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ "mb-2": true, active: verticalTab === 'Excavation Permit' })}
                        onClick={() => toggleVertical('Excavation Permit')}
                        id={`v-pills-${'Excavation Permit'.toLowerCase()}-tab`}
                      >
                       Excavation Permit
                      </NavLink>
                    </NavItem>
              </Nav>
            </Col>

            <Col md={9}>
              <TabContent activeTab={verticalTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="ConfinedSpace Permit">
                 <ConfinedSpaceQuestionnaireTab/>
                </TabPane>
              </TabContent>
              <TabContent activeTab={verticalTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="Lifting Permit">
                <LiftingPermitQuestionnaireTab/>
                </TabPane>
              </TabContent>
              <TabContent activeTab={verticalTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="WorkAtHeight Permit">
                <WorkAtHeightQuestionnaireTab/>
                </TabPane>
              </TabContent>
              <TabContent activeTab={verticalTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="GeneralWork Permit">
                <GeneralworkQuestionnaireTab/>
                </TabPane>
              </TabContent>
              <TabContent activeTab={verticalTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="HotWork Permit">
                <HotworkQuestionnaireTab/>
                </TabPane>
              </TabContent>
              <TabContent activeTab={verticalTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="Excavation Permit">
                <ExcavationPermitQuestionnaireTab/>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>


    </React.Fragment>
  );
};

export default QuestionnarieTab;
