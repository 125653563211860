import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";

// Creating the API slice for pageSettings
export const pageSettingsSlice = createApi({
  reducerPath: "pageSettings",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    // Feature Flag Endpoints
    getFeatureFlags: builder.query({
      query: () => "/organization/api/v1/app_featureflag/",
    }),
    getFeatureFlagById: builder.query({
      query: (id) => `/organization/api/v1/app_featureflag/${id}/`,
    }),
    updateFeatureFlagById: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/organization/api/v1/app_featureflag/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),

    // App Settings Endpoints
    getAppSettings: builder.query({
      query: () => "/organization/api/v1/app_settings/",
    }),
    getAppSettingById: builder.query({
      query: (id) => `/organization/api/v1/app_settings/${id}/`,
    }),
    updateAppSettingById: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/organization/api/v1/app_settings/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
  }),
});

// Exporting hooks for the queries and mutations
export const {
  useGetFeatureFlagsQuery,
  useGetFeatureFlagByIdQuery,
  useUpdateFeatureFlagByIdMutation,
  useGetAppSettingsQuery,
  useGetAppSettingByIdQuery,
  useUpdateAppSettingByIdMutation,
} = pageSettingsSlice;
