import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Loader from "Components/Common/Loader";
import TableContainer from "Components/Common/TableContainer";
import {
    useGetAllProjectsByUserRoleQuery,
  } from "slices/projects/projectSlice";

const ActiveProjects = () => {
    const [page, setPageState] = useState<{
        page: number,
        pageSize: number,
        search: string,
        status_name: string,
        start_date: string,
        due_date: string
      }>({
        page: 1,
        pageSize: 10,
        search: '',
        status_name: '',
        start_date: '',
        due_date: '',
      });
    
      const {
        data: allProjects,
        isLoading: isProjectLoading,
        isFetching: isProjectFetching,
        // refetch: refetchProject,
        // error: projectError,
      } = useGetAllProjectsByUserRoleQuery(page);

      const columns = useMemo(
        () => [
          {
            Header: "Project #",
            accessor: "project_id",
            filterable: false,
          },
          {
            Header: "Project Code",
            accessor: "project_short_name",
            filterable: false,
          },
          {
            Header: "Project",
            accessor: "project_name",
            filterable: false,
          },
          {
            Header: "Status",
            accessor: "status_name",
            filterable: false,
            Cell: (cell: any) => (
              <>
                <span className="badge bg-primary-subtle text-primary me-1">
                  {cell.row.original?.status_name}
                </span>
              </>
            ),
          },
        ],
        []
      );

    return (
        <React.Fragment>
            <Col xl={12}>
                <Card className='card-height-100'>
                    <CardHeader className="d-flex align-items-center">
                        <h4 className="card-title flex-grow-1 mb-0">Active Projects</h4>
                        {/* <div className="flex-shrink-0">
                            <Link to="#" className="btn btn-soft-info btn-sm shadow-none">Export Report</Link>
                        </div> */}
                    </CardHeader>
                    <CardBody className='min-width-height-400'>
                        <div className="table-responsive table-card">
                        {isProjectLoading ? (
                    <Loader />
                  ) : (
                        <TableContainer
                            columns={columns}
                            data={allProjects?.results || []}
                            isLoading={isProjectFetching}
                            isGlobalFilter={false}
                            isAddUserList={false}
                            customPageSize={page.pageSize}
                            className="custom-header-css"
                            divClass="min-width-height-400 table table-nowrap table-centered align-middle"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light text-muted"
                            thClass="table-light text-muted"
                            SearchPlaceholder="Search for Projects..."
                            isPagination={false}
                            isServerPagination={true}
                            serverPaginationProps={{
                                count: allProjects?.count || 0,
                                next: allProjects?.next || null,
                                page_size: allProjects?.page_size || 10,
                                previous: allProjects?.previous || null,
                                current_page: allProjects?.current_page || 1,
                                total_page: allProjects?.total_page || 0
                            }}
                            setPageState={setPageState}
                            />)}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default ActiveProjects;