import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";

export const notificationSlice = createApi({
  reducerPath: "notificationApi",
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getUserNotification: builder.query({
      query: (page) =>
        `/notification/api/v1/notify/?page=${page.page}&page_size=${page.pageSize}&is_read=${page.isRead}`,
    }),
  }),
});

export const {
  useGetUserNotificationQuery,
} = notificationSlice;
