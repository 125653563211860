import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
  Spinner,
  Table,
  Badge
} from "reactstrap";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  useGetPositionsQuery,
  useGetEmployeeDropDownByPositionQuery,
  useGetEmployeeDropDownByProjectPositionQuery,
  usePatchProjectTeamEmployeeAssignedMutation,
  useDeleteProjectTeamEmployeeAssignedMutation,
} from "slices/employee/employeeSlice";
import {
  useGetTeamByProjectIdQuery,
} from "slices/projects/projectSlice";


interface AddMultipleEmployeeProps {
  isModalOpen: boolean;
  handleModalToggle: () => void;
  projectId: string | undefined;
  submitLoading: boolean;
  handleSubmit: any;
}

interface OptionType {
  value: string;
  label: string;
  id: string;
  name: string;
  project_name: string;
  employee_fullname: string;
}

const AddMultipleEmployeeModal: React.FC<AddMultipleEmployeeProps> = ({
  isModalOpen,
  handleModalToggle,
  projectId,
  submitLoading,
  handleSubmit,
}) => {
  
  const [selectedPosition, setSelectedPosition] = useState<string | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedEmployees, setSelectedEmployeesAction] = useState<string[]>([]);
  const [selectedAssignedTeamId, setSelectedAssignedTeamId] = useState<string | null>(null);
  const [selectedAssignedTeamName, setSelectedAssignedTeamName] = useState<string | null>(null);

  const { data: teamData, isLoading, refetch} = useGetTeamByProjectIdQuery(projectId);
  const { data: positionData } = useGetPositionsQuery(undefined);

  const { data: employeeData, isLoading: employeesDataLoading, isFetching: employeesDataFetching } 
    = useGetEmployeeDropDownByProjectPositionQuery({
      projectId: projectId, 
      position: selectedPosition ? selectedPosition : '',
      teams: selectedAssignedTeamId ? selectedAssignedTeamId: ''
    });

  const handlePositionSelectChange = (selectedOption: OptionType | null): void => {
    setSelectedPosition(selectedOption?.value || '')
  };

  const handleTeamSelectChange = (selectedOption: OptionType | null): void => {
    setSelectedAssignedTeamId(selectedOption?.value || '')
    setSelectedAssignedTeamName(selectedOption?.label || '')
  };

  const handleSelectAllChange = () => {
    const currentEmployeeIds = employeeData?.map((employee: any) => employee.id) || [];
    if (currentEmployeeIds.every((id: any) => selectedEmployees.includes(id))) {
      // Deselect all current employees
      setSelectedEmployeesAction((prev) => prev.filter((id) => !currentEmployeeIds.includes(id)));
    } else {
      // Select all current employees
      setSelectedEmployeesAction((prev) => Array.from(new Set([...prev, ...currentEmployeeIds])));
    }
  };

  useEffect(() => {
    setShowPreview(false);
    setSelectedEmployeesAction([]);
  }, [isModalOpen])
  

  const handleEmployeeCheckboxChange = (employeeId: string) => {
    setSelectedEmployeesAction((prev) =>
      prev.includes(employeeId)
        ? prev.filter((id) => id !== employeeId) // Remove if already selected
        : [...prev, employeeId] // Add if not selected
    );
  };

  const handleShowPreview = () => {
    setSelectedPosition('');
    setSelectedAssignedTeamId('');
    setSelectedAssignedTeamName('');
    setShowPreview(true);
  }

  const handleSubmitAction = () => {
    handleSubmit(selectedEmployees);
    setSelectedPosition('');
    setSelectedAssignedTeamId('');
    setSelectedAssignedTeamName('');
    // setSelectedEmployeesAction([]);
    // setShowPreview(false);
  }

  return (
    <>
    {/* Add/Edit Document Modal */}
    <Modal size={'lg'} backdrop={'static'} isOpen={isModalOpen} toggle={handleModalToggle}>
        <ModalHeader>{'Select Employee'}</ModalHeader>
        <ModalBody>
          <LoadingOverlay active={isLoading} spinner text="Loading...">
            {!showPreview ? (
              // Form Section
              <form>
                <FormGroup>
                  <Label for="team_name-field" className="form-label">
                    Filter by Position
                  </Label>
                  <Select
                    name="position"
                    options={[
                      { value: '', label: 'Select Position' }, // Option to select none
                      ...(positionData?.map((position) => ({
                        value: position.id.toString(),
                        label: position.position_name,
                      })) || []),
                    ]}
                    onChange={handlePositionSelectChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="team_name-field" className="form-label">
                    Filter by Team
                  </Label>
                  <Select
                    name="team"
                    options={[
                      { value: '', label: 'Select Team' }, // Option to select none
                      ...(teamData?.map((team: any) => ({
                        value: team.id.toString(),
                        label: team.team_name,
                      })) || []),
                    ]}
                    onChange={handleTeamSelectChange}
                  />
                  {/* {!selectedAssignedTeamId && (<div className="text-danger mt-1">Team is required</div>)} */}
                </FormGroup>

                {/* Employee Table */}
                <div className="table-responsive mb-3">
                  <Table className="table-borderless align-middle mb-0" bordered>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            checked={
                              (employeeData?.length || 0) > 0 &&
                              employeeData?.every((employee: any) => selectedEmployees.includes(employee.id))
                            }
                            onChange={handleSelectAllChange}
                            disabled={!employeeData?.length}
                          />
                        </th>
                        <th scope="col"># No</th>
                        <th scope="col">Member Name</th>
                        <th scope="col">NRIC</th>
                        <th scope="col">Position</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(employeesDataLoading || employeesDataFetching) ? (
                        <tr>
                          <td colSpan={4} className="text-center mt-5 mb-5">
                            <Spinner color="primary" /> Loading employees...
                          </td>
                        </tr>
                      ) : (
                        employeeData?.map((employee: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedEmployees.includes(employee.id)}
                                onChange={() => handleEmployeeCheckboxChange(employee.id)}
                              />
                            </td>
                            <td>{employee.employee_number}</td>
                            <td>{employee.employee_fullname}</td>
                            <td>{employee.nric_mask}</td>
                            <td><Badge
                              key={index}
                              color="primary"
                              className="me-2"
                            >
                              {employee.position_name}
                            </Badge></td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>

                {/* Preview Button */}
                <Button
                  color="info"
                  type="button"
                  onClick={handleShowPreview}
                  // onClick={() => setShowPreview(true)}
                  disabled={selectedEmployees.length === 0}
                >
                  Preview
                </Button>
                &nbsp;&nbsp;

                {/* Cancel Button */}
                <Button
                  color="secondary"
                  onClick={() => {
                    handleModalToggle();
                    setSelectedEmployeesAction([]);
                  }}
                >
                  Cancel
                </Button>
              </form>
            ) : (
              // Preview Section
              <div>
                <h5 className="mb-3">Preview Selected Employees</h5>
                {/* <h5 className="mb-3">Team Name: {selectedAssignedTeamName}</h5> */}
                <Table className="table-borderless align-middle" bordered>
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Member Name</th>
                      <th scope="col">Number</th>
                      <th scope="col">Position</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(employeesDataLoading || employeesDataFetching) ? (
                      <tr>
                        <td colSpan={4} className="text-center mt-5 mb-5">
                          <Spinner color="primary" /> Loading employees...
                        </td>
                      </tr>
                    ) : (
                      selectedEmployees.map((employeeId) => {
                        const employee = employeeData?.find((e: any) => e.id === employeeId);
                        return employee ? (
                          <tr key={employee.id}>
                            <td>{employee.employee_fullname}</td>
                            <td>{employee.employee_number}</td>
                            <td>{employee.position_name}</td>
                          </tr>
                        ) : null;
                      })
                    )}
                  </tbody>
                </Table>

                {/* Confirm and Back Buttons */}
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmitAction}
                  disabled={(selectedEmployees.length === 0) || submitLoading}
                >
                  {submitLoading ? <Spinner size="sm" /> : 'Confirm Submit'}
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="secondary"
                  onClick={() => setShowPreview(false)}
                  disabled={(selectedEmployees.length === 0) || submitLoading}
                >
                  Back
                </Button>
              </div>
            )}
          </LoadingOverlay>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddMultipleEmployeeModal;
