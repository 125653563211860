import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Col, Container, Row } from 'reactstrap';
import { IsValidPermissions } from "../../Routes/AuthProtected";
import AdminDashboard from "./AdminDashboard";
import EmployeeDashboard from "./EmployeeDashboard";
import ClientDashboard from "./ClientDashboard";

// function DashboardRoute() {
//     const navigate = useNavigate(); // Get the navigate function

//     useEffect(() => {
//         if (IsValidPermissions(['VIEW_ADMIN_DASHBOARD']) === true) {
//             // Redirect to the admin dashboard
//             navigate("/admin-dashboard");
//         } 
//         if (IsValidPermissions(['VIEW_EMPLOYEE_DASHBOARD']) === true) {
//             // Redirect to the employee profile using getEmployeeUUID
//             navigate(`/employee-dashboard`);
//         }
//     }, []);

//     return null; // You can return null, as you don't need to render anything in this component
// }

const DashboardRoute = () => {

    const navigate = useNavigate();
    // useEffect(() => {
    //     if (IsValidPermissions(['VIEW_ADMIN_DASHBOARD']) === true) {
    //         // Redirect to the admin dashboard
    //         navigate("/admin-dashboard");
    //     } 
    //     if (IsValidPermissions(['VIEW_EMPLOYEE_DASHBOARD']) === true) {
    //         // Redirect to the employee profile using getEmployeeUUID
    //         navigate(`/employee-dashboard`);
    //     }
    // }, []);

    return (
        <React.Fragment>

            {IsValidPermissions(['VIEW_ADMIN_DASHBOARD']) ?
                <AdminDashboard /> : null}

            {IsValidPermissions(['VIEW_EMPLOYEE_DASHBOARD']) ?
                <EmployeeDashboard /> : null}

            {IsValidPermissions(['VIEW_LTA_DASHBOARD']) ? 
                <ClientDashboard /> : null}

        </React.Fragment>
    )
}

export default DashboardRoute;
