import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";

//Api Slice
import { loginSlice } from "./auth/login/loginSlice";
import { dashboardSlice } from "./dashboardProject/dashboardSlice";
import { employeeSlice } from "./employee/employeeSlice";
import { inspectionReportSlice } from "./InspectionReport/InspectionReportSlice";
import { taskSlice } from "./tasks/taskSlice";
import { projectSlice } from "./projects/projectSlice";
import { workflowSlice } from "./workflow/WorkFlowSlice";
import { resourceInventorySlice } from "./resourceInventory/inventorySlice";
import { pageAccessSlice } from "./pageAccess/PageAccessSlice";
import { pageSettingsSlice } from "./pageSettings/pageSettingsSlice";
import { safetyAppSlice } from "./safetyApp/safetyappSlice";
import { notificationSlice } from "./notification/NotificationSlice";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  ForgetPassword: ForgetPasswordReducer,
  [loginSlice.reducerPath]: loginSlice.reducer,
  [dashboardSlice.reducerPath]: dashboardSlice.reducer,
  [employeeSlice.reducerPath]: employeeSlice.reducer,
  [taskSlice.reducerPath]: taskSlice.reducer,
  [projectSlice.reducerPath]: projectSlice.reducer,
  [inspectionReportSlice.reducerPath]: inspectionReportSlice.reducer,
  [workflowSlice.reducerPath]: workflowSlice.reducer,
  [resourceInventorySlice.reducerPath]: resourceInventorySlice.reducer,
  [pageAccessSlice.reducerPath]: pageAccessSlice.reducer,
  [pageSettingsSlice.reducerPath]: pageSettingsSlice.reducer,
  [safetyAppSlice.reducerPath]: safetyAppSlice.reducer,
  [notificationSlice.reducerPath]: notificationSlice.reducer
});

export default rootReducer;
