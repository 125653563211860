import React, { useState, useEffect, useContext } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";

import { ServiceContext } from "context/ServiceContextProvider";
// import { useAuthServices } from "AuthServices/AuthServices";
import { useGetUserPermissionsByRoleIdQuery } from "slices/employee/employeeSlice";
import {useGetDashboardSummaryQuery} from "slices/dashboardProject/dashboardSlice";
// Import images
import avatar1 from "../../assets/images/user.png";

const ProfileDropdown: React.FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [employeeUuid, setEmployeeUuid] = useState<string | undefined>();
  // const { getSelectedRole } = useAuthServices();

  const {
    data: summaryList,
    // isLoading: isSummaryLoading,
    // refetch: refetchSummary,
    // error: summaryError,
  } = useGetDashboardSummaryQuery(undefined);

  // Access context
  const context = useContext(ServiceContext);
  const { selectedRoleName, selectedRoleId, setselectedRoleId, setSelectedRoleName } = context;

  const {
    data: permissionsData,
  } = useGetUserPermissionsByRoleIdQuery(selectedRoleId, {
    skip: !selectedRoleId,
  });

  // useEffect(() => {
  //   // Fetch selected role from session storage and update the context and Redux store
  //   const selectedRole = getSelectedRole();
  // }, [getSelectedRole]);



  // Handle the case where context might be undefined
  if (!context) {
    throw new Error(
      "ProfileDropdown must be used within a ServiceContextProvider"
    );
  }

  // Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newRoleId = e.target.value;
    const newRoleName = e.target.options[e.target.selectedIndex].text;

    localStorage.setItem(
      "selectedRole",
      JSON.stringify({ role_id: newRoleId, role_name: newRoleName })
    );
  
    setselectedRoleId(newRoleId);
    setSelectedRoleName(newRoleName);

    // Reload the page
    window.location.reload();
  };

  useEffect(() => {
    // const authUser = sessionStorage.getItem("authUser");
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      const user = JSON.parse(authUser);
      setUserName(user.employee_full_name || "");
      setEmployeeUuid(user.employee_uuid);
    }
  }, []);

  return (
    <Dropdown
      isOpen={isProfileDropdown}
      toggle={toggleProfileDropdown}
      className="ms-sm-3 header-item topbar-user"
    >
      <DropdownToggle tag="button" type="button" className="btn shadow-none">
        <span className="d-flex align-items-center">
          <img
            className="rounded-circle header-profile-user"
            src={summaryList?.employee_profile_image ? summaryList.employee_profile_image : avatar1}
            alt="Header Avatar"
          />
          <span className="text-start ms-xl-2">
            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
              {userName}
            </span>
            <span className="d-none badge bg-success d-xl-block ms-1 fs-12 text-white user-name-sub-text">
              {selectedRoleName}
            </span>
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <h6 className="dropdown-header">Welcome {userName}!</h6>
        <label htmlFor="roleSelector" className="dropdown-header" style={{ textAlign: "center", padding: 0 }}>
          Switch Role
        </label>
        <select
          id="roleSelector"
          // className="form-select"
          style={{
            margin: "auto",
            borderColor: "#c1cbcc",
            textAlign: "center",
            display: "flex",
            fontSize: "12px"
          }}
          value={selectedRoleId || ""}
          onChange={handleRoleChange}
        >
          <option value="" disabled>
            Select a role
          </option>
          {permissionsData?.role?.map(([roleId, roleName]: [number, string]) => (
            <option key={roleId} value={roleId}>
              {roleName}
            </option>
          ))}
        </select>
        <div className="dropdown-divider"></div>
        <DropdownItem>
          <Link to={`/profile/${employeeUuid}`}>
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </Link>
        </DropdownItem>
        <div className="dropdown-divider"></div>
        <DropdownItem href={`/pages-settings/`}>
          <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem href={`/logout`}>
          <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
          <span className="align-middle" data-key="t-logout">
            Logout
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
