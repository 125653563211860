import React, { useState, useCallback, useEffect } from "react";
import { Container, Col, Row, Spinner, UncontrolledAlert } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import Summary from "./Section";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useGetToolboxMeetingByIdQuery } from "slices/safetyApp/safetyappSlice";

const ToolBoxMeetingDetails = () => {
  document.title = "Tool Box Meeting Detail View";

  const { toolboxId: tooboxIdFromURL } = useParams<{ toolboxId: string }>();

  const {
    data: toolboxMeetingById,
    isLoading,
    isFetching,
    refetch: refetchTooboxById,
  } = useGetToolboxMeetingByIdQuery(tooboxIdFromURL);


  useEffect(() => {
    refetchTooboxById();
  }, []);

  if (isLoading) {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content" style={{ height: "80vh" }}>
            {" "}
            {/* Full viewport height */}
            <Container fluid className="h-100">
              <Row className="h-100 d-flex justify-content-center align-items-center">
                <Col lg={12} className="text-center">
                  <Spinner color="primary"></Spinner> <br /> <br />
                  <span className="text-muted">
                    Fetching Tool Box Meeting Information...
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container fluid>
        <BreadCrumb
          title="Home Work Permit Details"
          pageTitle="Tasks"
          style={{ "margin-top": "70px" }}
        />

        {toolboxMeetingById?.health_declaration?.length ===  0 ?
        <UncontrolledAlert color="warning" className="alert-additional material-shadow mb-3 text-dark">
          <div className="alert-body">
            <div className="d-flex">
              <div className="flex-shrink-0 me-3">
                <i className="ri-alert-line fs-16 align-middle"></i>
              </div>
              <div className="flex-grow-1">
                <h5 className="alert-heading">Action Required: Health Declaration Update!</h5>
                <p className="mb-0">
                  A minimum of one Health Declaration is mandatory to proceed with closing the Toolbox Meeting report.
                </p>
              </div>
            </div>
          </div>
        </UncontrolledAlert>
        : null}

        <Summary
          toolboxMeetingByIdData={toolboxMeetingById}
          isFetching={isFetching}
          refetchTooboxById={refetchTooboxById}
        />
        <ToastContainer closeButton={false} limit={1} />
      </Container>
    </React.Fragment>
  );
};

export default ToolBoxMeetingDetails;
