import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row, Button, Card, CardBody, CardTitle, Input, Modal, ModalBody, Spinner } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Tooltip } from 'react-tooltip';
import { useUpdateDeviceTypeMutation, useGetDeviceRedeemTokenQuery } from "slices/employee/employeeSlice";
import { ServiceContext } from "context/ServiceContextProvider";

const MobileAppLink = () => {
    const [selectedDevice, setSelectedDevice] = useState('');
    const [tooltipContent, setTooltipContent] = useState('Copy');

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { getEmployeeUUID } = useContext(ServiceContext);



    const { data: deviceTokenInfo, isLoading, refetch } = useGetDeviceRedeemTokenQuery(undefined);
    const [patchDevicdType, { isLoading: patchDevicdTypeLoading }] = useUpdateDeviceTypeMutation();

    console.log('deviceTokenInfo', deviceTokenInfo)


    useEffect(() => {
        if (deviceTokenInfo) {
            setSelectedDevice(deviceTokenInfo.device_type);
        }
    }, [deviceTokenInfo])


    const handleDeviceTypeChange = (e: any) => {
        setSelectedDevice(e.target.value);
        if (e.target.value === 'android' || e.target.value === 'ios') {
            setIsModalOpen(true);
        }
    };

    const handleConfirmDeviceType = async (e: any) => {
        e.preventDefault();
        await patchDevicdType({ device_type: selectedDevice, employee_uuid: getEmployeeUUID });
        await refetch();
        setIsModalOpen(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedDevice('');
    }

    const handleCopy = () => {
        const copyText = deviceTokenInfo.url;
        navigator.clipboard.writeText(copyText);
        setTooltipContent('Copied!');
        setTimeout(() => setTooltipContent('Copy'), 2000); // Reset tooltip content after 2 seconds
    };

    const renderAndroidContent = () => (
        <Card className="mt-4">
            <CardBody>
                <CardTitle tag="h5">
                <h2>< i className="ri-android-line text-success"></i> Android App</h2>
                </CardTitle>
                <Row>
                    <Col md={12} className="d-flex align-items-center justify-content-center">
                        <div>
                            <p>Scan QR to Download CompliPro app from <i className="ri-android-line text-success"></i> Play Store.</p>
                            <label>Download Link:</label>
                            <div className="d-flex align-items-center">
                                <Input
                                    type="text"
                                    value={deviceTokenInfo.url}
                                    readOnly
                                    className="mt-2 me-2"
                                />
                                <Button
                                    color="secondary"
                                    className="mt-2 d-flex align-items-center copy-link-class"
                                    onClick={handleCopy}
                                >
                                    <i className="ri-file-copy-line me-1 align-bottom text-white"></i>
                                </Button>
                            </div>
                            <div className='mt-5 text-center'>
                                <h6>Scan to Download <strong>CompliPro</strong> App:</h6>
                                <img src={deviceTokenInfo.qr_code} width={200} height={200} alt="QR Code" className="img-fluid rounded" />
                            </div>
                            <a href={deviceTokenInfo.url} target="_blank" rel="noreferrer">{deviceTokenInfo.url}</a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="d-flex align-items-center justify-content-center">
                        <div className="steps mt-4">
                            <h6>Steps to Download the App:</h6>
                            <ol>
                                <li>
                                    Scan the <strong>QR code</strong> above using your mobile device’s camera.
                                </li>
                                <li>
                                    Open the link in the <strong>Google Play Store</strong> (Android).
                                </li>
                                <li>
                                    <strong>Download</strong> and install the app on your device.
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );

    const renderIOSContent = () => (
        <Card className="mt-4">
            <CardBody>
                <CardTitle tag="h5">
                    <h2>< i className="ri-apple-fill text-black"></i> iOS App</h2>
                </CardTitle>
                <Row>
                    <Col md="12" className="d-flex align-items-center justify-content-center">
                        <div className="mt-3">
                            <p>Scan QR to Download CompliPro app using Redeem Code in < i className="ri-apple-fill text-black"></i> App Store.</p>
                            <label>Redeem Code:</label>
                            <div className="d-flex align-items-center">
                                <Input
                                    type="text"
                                    value={deviceTokenInfo.url}
                                    readOnly
                                    className="mt-2 me-2"
                                />
                                <Button
                                    color="secondary"
                                    className="mt-2 d-flex align-items-center copy-link-class"
                                    onClick={handleCopy}
                                >
                                    <i className="ri-file-copy-line me-1 align-bottom text-white"></i>
                                </Button>
                            </div>
                            <div className='mt-5 text-center'>
                                <h6>Scan to Download <strong>CompliPro</strong> App:</h6>
                                <img src={deviceTokenInfo.qr_code} width={200} height={200} alt="QR Code" className="img-fluid rounded" />
                            </div>
                            <a href={deviceTokenInfo.url} target="_blank" rel="noreferrer">{deviceTokenInfo.url}</a>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className="d-flex align-items-center justify-content-center">
                        <div className="steps mt-4">
                            <h6>Steps to Download the App:</h6>
                            <ol>
                                <li>
                                    Scan the <strong>QR code</strong> above using your mobile device’s camera.
                                </li>
                                <li>
                                    Open the link associated with the redeem code in the <strong>App Store</strong> (iOS).
                                </li>
                                <li>
                                    <strong>Download</strong> and install the app on your device.
                                </li>
                            </ol>
                            <p>
                                <strong>Important:</strong> The redeem code can only be used <strong>once</strong>. If you encounter any issues or need assistance, please contact your account administrator.
                            </p>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );

    if (isLoading) {
        return <Container fluid>
            <BreadCrumb title="Mobile App" pageTitle="Dashboard" />
            <Row>
                <Spinner />
            </Row>
        </Container>
    }

    return (
        <React.Fragment>
            <Tooltip
                style={{ zIndex: 10 }}
                anchorSelect=".copy-link-class"
                content={tooltipContent}
            />

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Mobile App" pageTitle="Dashboard" />
                    
                    <Row>
                        <Col>
                            <div style={{ width: "50%", margin: "auto", textAlign: "center" }}>
                                <label htmlFor="device-type-select" className="form-label">
                                    Mobile Device Type
                                </label>
                                <select
                                    id="device-type-select"
                                    className="form-select"
                                    style={{ textAlign: "center", fontSize: '18px' }}
                                    value={selectedDevice}
                                    onChange={handleDeviceTypeChange}
                                >
                                    <option value="">Select Device Type</option>
                                    <option value="android">Android Device</option>
                                    <option value="ios">iOS Device</option>
                                </select>
                            </div>
                            {deviceTokenInfo?.device_type === 'android' && renderAndroidContent()}
                            {deviceTokenInfo?.device_type === 'ios' && renderIOSContent()}
                            {deviceTokenInfo?.device_type === '' && <>
                                <Row>
                                    <Col fluid>
                                        <div className='text-muted fs-14 fs-bold text-center mt-5'>No Device Type Assigned</div>
                                    </Col>
                                </Row>
                            </>}
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={isModalOpen} centered={true}>
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <i className="ri-delete-bin-line display-5 text-danger"></i>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Confirm Device Type :
                                {selectedDevice === 'android' && (<span className='text-uppercase fs-16 fw-bold' style={{ color: "#A4C639" }}><i className="ri-android-fill"></i> {selectedDevice}</span>)}
                                {selectedDevice === 'ios' && (<span className='text-uppercase fs-16 fw-bold' style={{ color: "black" }}><i className="ri-apple-fill"></i> {selectedDevice}</span>)}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={closeModal}
                            disabled={patchDevicdTypeLoading}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={handleConfirmDeviceType}
                            disabled={patchDevicdTypeLoading}
                        >
                            {patchDevicdTypeLoading ? <Spinner size="sm" /> : <>Yes, Confirm!</>}
                        </button>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default MobileAppLink;
