import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardRoute from "../pages/DashboardProject/DashboardRoute";

// Project
import ProjectList from "../pages/Projects/ProjectList";
import CreateProject from "../pages/Projects/CreateProject";
import ProjectOverview from "pages/Projects/ProjectOverview/Section";

//Task
import TaskDetails from "../pages/Tasks/TaskDetails";
import TaskList from "../pages/Tasks/TaskList";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
//pages
import SimplePage from "../pages/Pages/Profile/SimplePage/SimplePage";
import Settings from "../pages/Pages/Profile/Settings/Settings";

import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error401 from "../pages/AuthenticationInner/Errors/Error401";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
// import UserProfile from "../pages/Authentication/user-profile";

// import Kanbanboard from "pages/Tasks/KanbanBoard";

import EmployeeList from "../pages/Employee/EmployeeList/index";

import EmployeeProfile from "pages/Employee/EmployeeProfile";
import UiRatings from "../pages/AdvanceUi/UiRatings/UiRatings";

import InspectionReportListView from "pages/InspectionReport/InspectionReportList/InspectionReportListView";
import ReportFormWizard from "pages/InspectionReport/InspectionReportWizard/ReportWizard";

import ReportOverview from "pages/InspectionReport/InspectionReportView/Section";

import ReportEditOverview from "pages/InspectionReport/InspectionReportEdit/Section";

import WorkflowListView from "pages/WorkFlow/WorkFlowList/WorkFlowListView";
import RoleAccessList from "pages/Roles/RoleAccessList/RoleAccessList";

import MeasurementsListView from "pages/Inventory/Measurements/MeasurementsList";
import SupplierListView from "pages/Inventory/Suppliers/SuppliersListView";
import CategoriesListView from "pages/Inventory/categories/CategoriesListView";
import ProductListView from "pages/Inventory/Products/ProductListView";
import StockListView from "pages/Inventory/Stocks/StocksListView";
import SupplierDetails from "pages/Inventory/Suppliers/SuppliersView";
import ProductDetailView from "pages/Inventory/Products/ProductView";

import InventoryDashBoard from "pages/Inventory/DashBoard/InventoryDashbord";

import PageAccessListView from "pages/Roles/pageAcess/pageAcessList";

import WorkFlowApprovalsListView from "pages/WorkFlow/WorkFlowApprovels/WorkFlowApprovelsList";
import WorkFlowApprovalsOverview from "pages/WorkFlow/WorkFlowApprovels/WorkFlowApprovelsOverview/Section";
import SelectRole from "pages/Authentication/selectRoles";

import PageSettings from "pages/Pages/Profile/Settings/PageSettings";

import HomeworkPermitListView from "pages/SafetyApp/HomeWorkPermit/HomeWorkPermitList";
import HomeworkPermitWizard from "pages/SafetyApp/HomeWorkPermit/HomeWorkPermitReport/HomeWorkPermitWizard";
import HomeWorkPermitDetailView from "pages/SafetyApp/HomeWorkPermit/HomeWorkPermitDetailView";
import HomePermitDetails from "pages/SafetyApp/HomeWorkPermit/HomeWorkPermitDetail/HomeWorkPermitOverview";
import SafetyAppWorkflowListView from "pages/SafetyApp/WorkFlow/WorkFlowListView";
import SafetyAPPWorkApprovalsListView from "pages/SafetyApp/WorkFlowApprovels/WorkFlowApprovelsList";

// Hot Work Permit 

import HotworkPermitListView from "pages/SafetyApp/HotWorkPermit/HotWorkPermitList";
import HotworkPermitWizard from "pages/SafetyApp/HotWorkPermit/HotWorkPermitReport/HotWorkPermitWizard";
import HotPermitDetails from "pages/SafetyApp/HotWorkPermit/HotWorkPermitDetail";


//General Work Permit
import GeneralworkPermitListView from "pages/SafetyApp/GeneralWorkPermit/GeneralWorkPermitList";
import GeneralworkPermitWizard from "pages/SafetyApp/GeneralWorkPermit/GeneralWorkPermitReport/GeneralWorkPermitWizard";
import GeneralPermitDetails from "pages/SafetyApp/GeneralWorkPermit/GeneralWorkPermitDetail";

//WorkAtHeight Permit
import WorkAtHeightPermitListView from "pages/SafetyApp/WorkAtHeightPermit/WorkAtHeightPermitList";
import WorkAtHeightPermitWizard from "pages/SafetyApp/WorkAtHeightPermit/WorkAtHeightPermitReport/WorkAtHeightPermitWizard";
import WorkAtHeightPermitDetails from "pages/SafetyApp/WorkAtHeightPermit/WorkAtHeightDetail";

//ExcavationWork Permit 
import ExcavationWorkPermitListView from "pages/SafetyApp/ExcavationWorkPermit/ExcavationWorkPermitList";
import ExcavationPermitWizard from "pages/SafetyApp/ExcavationWorkPermit/ExcavationWorkPermitReport/ExcavationPermitWizard";
import ExcavationPermitDetails from "pages/SafetyApp/ExcavationWorkPermit/ExcavationWorkDetail";

//LiftingWork Permit
import LiftingWorkPermitListView from "pages/SafetyApp/LiftingWorkPermit/LiftingWorkPermitList";
import LiftingWorkPermitWizard from "pages/SafetyApp/LiftingWorkPermit/LiftingWorkPermitReport/LiftingPermitWizard";
import LiftingPermitDetails from "pages/SafetyApp/LiftingWorkPermit/LiftingWorkPermitDetail";

//ConfinedSpaceWork Permit
import ConfinedSpaceWorkPermitListView from "pages/SafetyApp/ConfinedSpaceWorkPermit/ConfinedSpaceWorkPermitList";
import ConfinedSpacePermitWizard from "pages/SafetyApp/ConfinedSpaceWorkPermit/ConfinedSpaceWorkPermitReport/ConfinedSpacePermitWizard";
import ConfinedSpacePermitDetails from "pages/SafetyApp/ConfinedSpaceWorkPermit/ConfinedSpaceWorkDetail";

//----ToolBoxMeeting----//
import ToolBoxMeetingListView from "pages/SafetyApp/ToolBoxMeeting/ToolBoxMeetingListView.";
import ToolBoxMeetingWizard from "pages/SafetyApp/ToolBoxMeeting/ToolBoxMeetingReport/ToolBoxMeetingWizard";
import ToolBoxMeetingDetails from "pages/SafetyApp/ToolBoxMeeting/ToolBoxMeetingDetail/ToolBoxMeeting";
import MobileAppLink from "pages/MobileIosLink/MobileIosLink";



const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardRoute />, permissions: ["VIEW_ADMIN_DASHBOARD", "VIEW_EMPLOYEE_DASHBOARD"]},
  { path: "/mobile-app", component: <MobileAppLink />},
  // { path: "/admin-dashboard", component: <AdminDashboard /> , permissions: ["VIEW_ADMIN_DASHBOARD"]},
  // { path: "/employee-dashboard", component: <EmployeeDashboard /> , permissions: ["VIEW_EMPLOYEE_DASHBOARD"]},

  //page_access
 
  //---- safety app ----//

  //ToolBox View 
  { path: "/toolboxmeeting-list", component: <ToolBoxMeetingListView />, permissions: ["VIEW_ONLY_TOOLBOX_MEETING", "VIEW_ALL_TOOLBOX_MEETING"]},
  { path: "/toolbox-meeting-wizard/:selectedProject", component: <ToolBoxMeetingWizard />, permissions: ["ADD_TOOLBOX_MEETING", "EDIT_TOOLBOX_MEETING"]},
  { path: "/toolbox-meeting-wizard/:selectedProject/:toolBoxId", component: <ToolBoxMeetingWizard />, permissions: ["ADD_TOOLBOX_MEETING", "EDIT_TOOLBOX_MEETING"]},
  { path: "/toolbox-meeting-detail-view/:toolboxId/", component: <ToolBoxMeetingDetails />, permissions: ["VIEW_ONLY_TOOLBOX_MEETING", "VIEW_ALL_TOOLBOX_MEETING"]},


  //Homework Permit
  // { path: "/homeworkpermit-list", component: <HomeworkPermitListView /> },
  // { path: "/homework-permit-wizard/:selectedProject", component: <HomeworkPermitWizard /> },
  { path: "/safety-app-workflow-list", component: <SafetyAppWorkflowListView /> },
  // { path: "/homework-permit-wizard/:selectedProject/:hwPermitNo/", component: <HomeworkPermitWizard /> },
  // { path: "/homework-permit-detail-view/:hwPermitNo/", component: <HomeWorkPermitDetailView /> },
  // { path: "/homework-permit-details/:hwPermitNo/", component: <HomePermitDetails /> },
  { path: "/safety-app-workflow-approvel-list", component: <SafetyAPPWorkApprovalsListView /> },

  //Hotwork permit
  { path: "/hotworkpermit-list", component: <HotworkPermitListView />, permissions: ["VIEW_ONLY_HOT_WORK_PERMIT", "VIEW_ALL_HOT_WORK_PERMIT"]},
  { path: "/hotwork-permit-wizard/:selectedProject", component: <HotworkPermitWizard />, permissions: ["ADD_HOT_WORK_PERMIT", "EDIT_HOT_WORK_PERMIT"]},
  { path: "/hotwork-permit-wizard/:selectedProject/:hwPermitNo/", component: <HotworkPermitWizard />, permissions: ["ADD_HOT_WORK_PERMIT", "EDIT_HOT_WORK_PERMIT"]},
  { path: "/hotwork-permit-details/:hwPermitNo/", component: <HotPermitDetails />, permissions: ["VIEW_ONLY_HOT_WORK_PERMIT", "VIEW_ALL_HOT_WORK_PERMIT"]},

  //General Work Permit
  { path: "/generalworkpermit-list", component: <GeneralworkPermitListView />, permissions: ["VIEW_ONLY_GENERAL_WORK_PERMIT", "VIEW_ALL_GENERAL_WORK_PERMIT"]},
  { path: "/generalwork-permit-wizard/:selectedProject", component: <GeneralworkPermitWizard />, permissions: ["ADD_GENERAL_WORK_PERMIT", "EDIT_GENERAL_WORK_PERMIT"]},
  { path: "/generalwork-permit-wizard/:selectedProject/:hwPermitNo/", component: <GeneralworkPermitWizard />, permissions: ["ADD_GENERAL_WORK_PERMIT", "EDIT_GENERAL_WORK_PERMIT"]},
  { path: "/generalwork-permit-details/:hwPermitNo/", component: <GeneralPermitDetails />, permissions: ["VIEW_ONLY_GENERAL_WORK_PERMIT", "VIEW_ALL_GENERAL_WORK_PERMIT"]},

  //WorkAtHeight Permit 
  { path: "/workatheightpermit-list", component: <WorkAtHeightPermitListView />, permissions: ["VIEW_ONLY_WORK_AT_HEIGHT_PERMIT", "VIEW_ALL_WORK_AT_HEIGHT_PERMIT"]},
  { path: "/workatheight-permit-wizard/:selectedProject", component: <WorkAtHeightPermitWizard />, permissions: ["ADD_WORK_AT_HEIGHT_PERMIT", "EDIT_WORK_AT_HEIGHT_PERMIT"]},
  { path: "/workatheight-permit-wizard/:selectedProject/:hwPermitNo/", component: <WorkAtHeightPermitWizard />, permissions: ["ADD_WORK_AT_HEIGHT_PERMIT", "EDIT_WORK_AT_HEIGHT_PERMIT"]},
  { path: "/workatheight-permit-details/:hwPermitNo/", component: <WorkAtHeightPermitDetails />, permissions: ["VIEW_ONLY_WORK_AT_HEIGHT_PERMIT", "VIEW_ALL_WORK_AT_HEIGHT_PERMIT"]},

  //ExcavationWork Permit 
  { path: "/excavationpermit-list", component: <ExcavationWorkPermitListView />, permissions: ["VIEW_ONLY_EXCAVATION_PERMIT", "VIEW_ALL_EXCAVATION_PERMIT"]},
  { path: "/excavation-permit-wizard/:selectedProject", component: <ExcavationPermitWizard />, permissions: ["ADD_EXCAVATION_PERMIT", "EDIT_EXCAVATION_PERMIT"]},
  { path: "/excavation-permit-wizard/:selectedProject/:hwPermitNo/", component: <ExcavationPermitWizard />, permissions: ["ADD_EXCAVATION_PERMIT", "EDIT_EXCAVATION_PERMIT"]},
  { path: "/excavation-permit-details/:hwPermitNo/", component: <ExcavationPermitDetails />, permissions: ["VIEW_ONLY_EXCAVATION_PERMIT", "VIEW_ALL_EXCAVATION_PERMIT"]},

  //LiftingWork Permit
  { path: "/lifting-permit-list", component: <LiftingWorkPermitListView />, permissions: ["VIEW_ONLY_LIFTING_PERMIT", "VIEW_ALL_LIFTING_PERMIT"]},
  { path: "/lifting-permit-wizard/:selectedProject", component: <LiftingWorkPermitWizard />, permissions: ["ADD_LIFTING_PERMIT", "EDIT_LIFTING_PERMIT"]},
  { path: "/lifting-permit-wizard/:selectedProject/:hwPermitNo/", component: <LiftingWorkPermitWizard />, permissions: ["ADD_LIFTING_PERMIT", "EDIT_LIFTING_PERMIT"]},
  { path: "/lifting-permit-details/:hwPermitNo/", component: <LiftingPermitDetails />, permissions: ["VIEW_ONLY_LIFTING_PERMIT", "VIEW_ALL_LIFTING_PERMIT"]},

  //Confined Space Permit
  { path: "/confinedspace-permit-list", component: <ConfinedSpaceWorkPermitListView />, permissions: ["VIEW_ONLY_CONFINED_SPACE_PERMIT", "VIEW_ALL_CONFINED_SPACE_PERMIT"]},
  { path: "/confinedspace-permit-wizard/:selectedProject", component: <ConfinedSpacePermitWizard />, permissions: ["ADD_CONFINED_SPACE_PERMIT", "EDIT_CONFINED_SPACE_PERMIT"]},
  { path: "/confinedspace-permit-wizard/:selectedProject/:hwPermitNo/", component: <ConfinedSpacePermitWizard />, permissions: ["ADD_CONFINED_SPACE_PERMIT", "EDIT_CONFINED_SPACE_PERMIT"]},
  { path: "/confinedspace-permit-details/:hwPermitNo/", component: <ConfinedSpacePermitDetails />, permissions: ["VIEW_ONLY_CONFINED_SPACE_PERMIT", "VIEW_ALL_CONFINED_SPACE_PERMIT"]},

  //inventory
  { path: "/inventory-dashboard/:projectId", component: <InventoryDashBoard />, permissions: []},
  { path: "/stock-list", component: <StockListView />, permissions: ["VIEW_ALL_STOCKS"]},
  { path: "/measurment-list", component: <MeasurementsListView />, permissions: ["VIEW_ALL_MEASUREMENTS"]},
  { path: "/supplier-list", component: <SupplierListView />, permissions: ["VIEW_ALL_SUPPLIERS"] },
  { path: "/supplier-view/:supplierId", component: <SupplierDetails />, permissions: ["VIEW_ALL_SUPPLIERS"]},
  { path: "/category-list", component: <CategoriesListView />, permissions: ["VIEW_ALL_CATEGORIES"]},
  { path: "/product-list", component: <ProductListView />, permissions: ["VIEW_ALL_PRODUCTS"]},
  { path: "/product-view/:productId", component: <ProductDetailView />, permissions: ["VIEW_ALL_PRODUCTS"]},

  //roleAccess
  { path: "/roles-list", component: <RoleAccessList />, permissions: ["VIEW_ALL_ROLE_ACCESS"]},
  { path: "/page-access-list", component: <PageAccessListView />, permissions: ["VIEW_ALL_ROLE_AND_PAGE_ACCESS"]},

  //workflow
  { path: "/inventory-workflow-list", component: <WorkflowListView />, permissions: []},
  { path: "/inventory-workflowaccess-list", component: <WorkFlowApprovalsListView />, permissions: []},
  { path: "/workflow-approval-details/:projectId", component: <WorkFlowApprovalsOverview />, permissions: []},

  //reports
  { path: "/report-edit/:reportId", component: <ReportEditOverview />, permissions: []},
  { path: "/report-view/:id", component: <ReportOverview />, permissions: []},
  { path: "/report-list", component: <InspectionReportListView />, permissions: []},
  { path: "/inspection-report", component: <ReportFormWizard />, permissions: []},

  //Projects
  { path: "/apps-projects-list", component: <ProjectList />, permissions: ["VIEW_ONLY_PROJECT", "VIEW_ALL_PROJECT"]},
  { path: "/apps-projects-overview/:project_id", component: <ProjectOverview />, permissions: ["VIEW_ONLY_PROJECT", "VIEW_ALL_PROJECT"]},
  { path: "/apps-projects-create", component: <CreateProject />, permissions: ["ADD_PROJECT", "EDIT_PROJECT"]},

  //Task
  // { path:"/apps-tasks-kanban", component: <Kanbanboard/> },

  { path: "/apps-tasks-list-view-create", component: <TaskList />, permissions: ["ADD_TASK", "EDIT_TASK"]},
  { path: "/apps-tasks-list-view", component: <TaskList />, permissions: ["VIEW_ONLY_TASK", "VIEW_ALL_TASK"]},
  { path: "/apps-tasks-details/:task_id", component: <TaskDetails />, permissions: ["VIEW_ONLY_TASK", "VIEW_ALL_TASK"]},

  //Pages
  // { path: "/pages-profile/:employee_uuid", component: <SimplePage /> },
  { path: "/profile/:employee_uuid", component: <EmployeeProfile />, permissions: ["VIEW_ONLY_MEMBERS", "VIEW_ALL_MEMBERS"]},
  // { path: "/pages-profile-settings/", component: <Settings /> },

  { path: "/pages-settings/", component: <PageSettings />, permissions: []},

  //User Profile
  // { path: "/profile", component: <UserProfile /> },

  { path: "/members-list", component: <EmployeeList />, permissions: ["VIEW_ONLY_MEMBERS", "VIEW_ALL_MEMBERS"]},

  { path: "/advance-ui-ratings", component: <UiRatings />, permissions: []},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/auth-404-cover" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //role selection
  { path: "/role-select", component: <SelectRole /> },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-401-basic", component: <Error401 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
