import React, { Fragment, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Spinner,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useUpdateRoleByIdMutation,
  useGetRoleByIdQuery,
  useCreateRoleMutation,
  useGetPageAccessesQuery,
} from "slices/pageAccess/PageAccessSlice";
import { useGetRolesQuery } from "slices/workflow/WorkFlowSlice";

interface AddRolesProps {
  setShowAddComponent: (value: boolean) => void;
  roleId?: string;
}

function AddRoles({ setShowAddComponent, roleId }: AddRolesProps) {
  const {
    data: rolesDetailsById,
    refetch: roleRefetchById,
    isLoading: loadingRolesInfo,
  } = useGetRoleByIdQuery(roleId, { skip: !roleId });
  const {
    data: getAllPageAccess,
    isLoading: loadingGetPageAccessFn,
    refetch: refetchPageAccess,
  } = useGetPageAccessesQuery(undefined);
  const [createRole, { isLoading: loadingPostNewRole }] =
    useCreateRoleMutation();
  const [updateRole, { isLoading: loadingPatchRoleInfo }] =
    useUpdateRoleByIdMutation();
  const { refetch: refetchRoles } = useGetRolesQuery(undefined);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      page_access: [] as number[],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Role name is required"),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      const action = roleId ? updateRole : createRole;
      const payload = roleId ? { id: roleId, updateData: values } : values;

      try {
        const response = await action(payload).unwrap();
        refetchRoles();
        toast.success(roleId ? "Role Updated" : "New Role Added!");
        setShowAddComponent(false);
        formik.resetForm();
      } catch (error) {
        if (error) {
          toast.error(`Error: ${error}`);
        } else {
          toast.error(`Error: ${error}`);
        }
      }
    },
  });

  const handleCancelButton = () => {
    setShowAddComponent(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (roleId && rolesDetailsById) {
      formik.setValues({
        name: rolesDetailsById.name || "",
        description: rolesDetailsById.description || "",
        page_access: rolesDetailsById.page_access || [],
      });
      roleRefetchById();
    }
  }, [roleId, rolesDetailsById, roleRefetchById]);

  useEffect(() => {
    if (getAllPageAccess) {
      refetchPageAccess();
    }
  }, [getAllPageAccess, refetchPageAccess]);

  const handlePageAccessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const currentPageLabel = e.target.getAttribute('aria-label');
    const labelParts = currentPageLabel?.split('-');

    if (labelParts && labelParts?.length>0){
      const category = labelParts[0];
      const label = labelParts[1];

      const viewAllCheckbox = document.querySelector(`input[aria-label="${category}-View All"]`) as HTMLInputElement;
      const viewOnlyCheckbox = document.querySelector(`input[aria-label="${category}-View Only"]`) as HTMLInputElement;

      if (category === "Admin Dashboard" && checked && label === "View All"){
        const viewAllEmployeeDashboardCheckbox = document.querySelector(`input[aria-label="Employee Dashboard-View All"]`) as HTMLInputElement;
        if (viewAllEmployeeDashboardCheckbox) {
          viewAllEmployeeDashboardCheckbox.disabled = true
        }
      }

      if (category === "Admin Dashboard" && checked === false && label === "View All"){
        const viewAllEmployeeDashboardCheckbox = document.querySelector(`input[aria-label="Employee Dashboard-View All"]`) as HTMLInputElement;
        if (viewAllEmployeeDashboardCheckbox) {
          viewAllEmployeeDashboardCheckbox.disabled = false
        }
      }

      if (category === "Employee Dashboard" && checked && label === "View All"){
        const viewAllAdminDashboardCheckbox = document.querySelector(`input[aria-label="Admin Dashboard-View All"]`) as HTMLInputElement;
        if (viewAllAdminDashboardCheckbox) {
          viewAllAdminDashboardCheckbox.disabled = true
        }
      }

      if (category === "Employee Dashboard" && checked === false && label === "View All"){
        const viewAllAdminDashboardCheckbox = document.querySelector(`input[aria-label="Admin Dashboard-View All"]`) as HTMLInputElement;
        if (viewAllAdminDashboardCheckbox) {
          viewAllAdminDashboardCheckbox.disabled = false
        }
      }

      if (checked && label === "View All"){
        if (viewOnlyCheckbox) viewOnlyCheckbox.disabled = true
      }else if (checked && label === "View Only"){
       if (viewAllCheckbox) viewAllCheckbox.disabled = true
      }

      if (checked === false){
        if (viewOnlyCheckbox) viewOnlyCheckbox.disabled = false;
        if (viewAllCheckbox) viewAllCheckbox.disabled = false;
      }
    }

    formik.setFieldValue(
      "page_access",
      checked
        ? [...formik.values.page_access, parseInt(value)]
        : formik.values.page_access.filter((id) => id !== parseInt(value))
    );
  };

  const renderPageAccessCheckboxes = () => {
    const groupedByCategory = getAllPageAccess?.reduce(
      (acc: any, page: any) => {
        if (!acc[page.page_category]) {
          acc[page.page_category] = [];
        }
        acc[page.page_category].push(page);
        return acc;
      },
      {}
    );

    return Object.keys(groupedByCategory || {})
      .sort()
      .map((category) => {
        const viewAllSelector = `input[aria-label="${category}-View All"]`;
        const viewOnlySelector = `input[aria-label="${category}-View Only"]`;

      const viewAllCheckbox = document.querySelector<HTMLInputElement>(viewAllSelector);
      const viewOnlyCheckbox = document.querySelector<HTMLInputElement>(viewOnlySelector);
      const viewAllEmployeeDashboardCheckbox = document.querySelector(`input[aria-label="Employee Dashboard-View All"]`) as HTMLInputElement;
      const viewAllAdminDashboardCheckbox = document.querySelector(`input[aria-label="Admin Dashboard-View All"]`) as HTMLInputElement;

      if (viewAllEmployeeDashboardCheckbox && viewAllEmployeeDashboardCheckbox.checked) {
        if (viewAllAdminDashboardCheckbox) viewAllAdminDashboardCheckbox.disabled = true;
      }

      if (viewAllAdminDashboardCheckbox && viewAllAdminDashboardCheckbox.checked) {
        if (viewAllEmployeeDashboardCheckbox) viewAllEmployeeDashboardCheckbox.disabled = true;
      }

      if (viewAllCheckbox && viewAllCheckbox.checked) {
        if (viewOnlyCheckbox) viewOnlyCheckbox.disabled = true;
      }

      if (viewOnlyCheckbox && viewOnlyCheckbox.checked) {
        if (viewAllCheckbox) viewAllCheckbox.disabled = true;
      }

        return <div key={category} style={{ marginBottom: "10px" }}>
          <h5 className="text-primary">{category.replace(/\./g, ' - ')}</h5>
          <hr/>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {groupedByCategory[category]
              .sort((a: any, b: any) => a.sort - b.sort)
              .map((page: any) => (
                <div
                  key={page.id}
                  style={{ width: "15%", paddingLeft: "20px" }}
                  className="mt-2 mb-2"
                >
                  <input
                    type="checkbox"
                    id={`page-access-${page.id}`}
                    value={page.id}
                    checked={formik.values.page_access.includes(page.id)}
                    onChange={handlePageAccessChange}
                    aria-label={`${category}-${page.action_name}`}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor={`page-access-${page.id}`}>
                    {page.action_name}
                  </label>
                </div>
              ))}
          </div>
        </div>
  });
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="col-xl-12 col-xxl-12">
        <Card className="az-card">
          <CardHeader>
            <h3>{roleId ? "Edit" : "Add"} Role</h3>
          </CardHeader>
          <LoadingOverlay
            active={loadingRolesInfo}
            spinner
            text="Fetching..."
            className="custom-overlay"
          >
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col className="col-lg-6 mb-2">
                    <label>
                      Role Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Type Text"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{
                        width: "100%",
                        padding: "8px",
                        marginBottom: "8px",
                      }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div style={{ color: "red", marginBottom: "8px" }}>
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </Col>
                  <Col className="col-md-6 mb-2">
                    <label>Description</label>
                    <input
                      type="text"
                      name="description"
                      placeholder="Description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{
                        width: "100%",
                        padding: "8px",
                        marginBottom: "8px",
                      }}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div style={{ color: "red", marginBottom: "8px" }}>
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-12 mt-2">
                    <hr />
                    <h3 className="mb-4 mt-4">Page Access</h3>
                    <div>
                      {loadingGetPageAccessFn ? (
                        <span>Loading Page Access...</span>
                      ) : (
                        renderPageAccessCheckboxes()
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="col-md-12">
                    <hr />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="danger btn-sm"
                        onClick={handleCancelButton}
                        disabled={loadingPostNewRole || loadingPatchRoleInfo}
                      >
                        Cancel
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        variant="primary btn-sm"
                        type="submit"
                        disabled={
                          loadingRolesInfo ||
                          loadingPostNewRole ||
                          loadingPatchRoleInfo
                        }
                      >
                        {loadingPostNewRole || loadingPatchRoleInfo ? (
                          <>
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-2"
                            />
                            Loading...
                          </>
                        ) : roleId ? (
                          "Save"
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </LoadingOverlay>
        </Card>
      </div>
    </Fragment>
  );
}

export default AddRoles;
