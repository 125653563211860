import React, { useMemo, useState, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,

} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useGetProjectsByAssignedUserQuery } from "slices/projects/projectSlice";
import { 
  useGetToolboxMeetingViewQuery,
  useGenerateHomeworkPermitPDFMutation
 } from "slices/safetyApp/safetyappSlice";
import TableContainer from "Components/Common/TableContainer";
import Loader from "Components/Common/Loader";
import { Tooltip } from "react-tooltip";
import { BadgeStatus } from "helpers/badgeStatus";
import { formatDateTime } from "helpers/date_utils";
import { STATUS_COLORS } from "helpers/common_helper";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

const ToolBoxMeetingListView = () => {
  const [page, setPageState] = useState<{
    page: number,
    pageSize: number,
    search: string,
    status: string,
    start_date_of_work: string,
    end_date_of_work: string
  }>({
    page: 1,
    pageSize: 10,
    search: '',
    status: '',
    start_date_of_work: '',
    end_date_of_work: '',
  });
  const { data: toolboxMeetings, isLoading, isFetching } = useGetToolboxMeetingViewQuery(page);
  const { data: projectData } = useGetProjectsByAssignedUserQuery(undefined);
  const [generatePDF] = useGenerateHomeworkPermitPDFMutation();
  const navigate = useNavigate();

  // State for Modal and Selected Project
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  // Toggle Modal
  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);


  // Map Projects to Dropdown Options
  const projectOptions = useMemo(() => {
    return (
      projectData?.map((project: any) => ({
        value: project.id,
        label: project.project_name,
      })) || []
    );
  }, [projectData]);

  // Redirect to Home Work Permit Wizard with Project ID in the URL
  const handleCreateNewToolBox = () => {
    if (selectedProject) {
      navigate(`/toolbox-meeting-wizard/${selectedProject}`);
    }
  };

  // Define Columns for Table
  const columns = useMemo(
    () => [
      {
        Header: "Meeting ID",
        accessor: "toolbox_id",
        Cell: ({
          row: {
            original: { toolbox_id },
          },
        }: any) => (
          <Link
            to={`/toolbox-meeting-detail-view/${toolbox_id}/`}
            className="text-primary d-inline-block"
          >
            {toolbox_id}
          </Link>
        ),
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      {
        Header: "Start & End Date of Work",
        accessor: "start_date_of_work", // Start Date
        Cell: ({
          row: {
            original: { start_date_of_work, start_time_of_work, end_date_of_work, end_time_of_work },
          },
        }: any) => (<>{formatDateTime(start_date_of_work, 'date')} {formatDateTime(start_time_of_work, 'time')} <i className="las la-arrows-alt-h"></i> {formatDateTime(end_date_of_work, 'date')} {formatDateTime(end_time_of_work, 'time')}</>),
      },
      // {
      //   Header: "End Date of Work",
      //   accessor: "end_date_of_work", // End Date
      //   Cell: ({ value }: any) => formatDateTime(value, 'date'), // Format Date
      // },
      {
        Header: "Status",
        accessor: "report_status_name",
        Cell: (cell: { value: any }) => (
          <BadgeStatus value={cell.value} color={STATUS_COLORS[cell.value]} />
        ),
      },
      {
        Header: "Conducted By",
        accessor: "submited_by_name",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({
          row: {
            original: { toolbox_id, report_status_name, project },
          },
        }: any) => (
          <div className="d-flex gap-2">
            {(IsValidPermissions(['VIEW_ALL_TOOLBOX_MEETING']) || IsValidPermissions(['VIEW_ONLY_TOOLBOX_MEETING'])) &&
            <Link
              to={`/toolbox-meeting-detail-view/${toolbox_id}/`}
              className="text-primary"
            >
              <i className="ri-eye-fill fs-16"></i>
            </Link>
            }
            {(IsValidPermissions(['EDIT_TOOLBOX_MEETING'])) && (report_status_name === 'Draft') ?
            <Link
              to={`/toolbox-meeting-wizard/${project}/${toolbox_id}/`}
              className="text-warning"
            >
              <i className="ri-pencil-fill fs-16"></i>
            </Link>
            : <><i className="ri-pencil-fill fs-16 edit-task-status-class" title="Edit" style={{ color: "gray" }}></i></>}
            {IsValidPermissions(['DOWNLOAD_TOOLBOX_MEETING']) && (report_status_name === 'Linked' || report_status_name === 'Submitted' || report_status_name === 'Closed') ?
              <li className="list-inline-item edit" title="Export Permit">
                <Link
                  to={`#`}
                  className="text-primary d-inline-block edit-item-btn export-task-status-class"
                  onClick={() => generatePDF(toolbox_id)}
                >
                  <i className="ri-download-fill"></i>
                </Link></li> : <></>
            }
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".view-task-status-class"
        place="bottom"
        content="View"
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Toolbox Meeting List" pageTitle="Safety App" />
          <Row>
            <Col lg={12}>
              <Card id="toolboxMeetingList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm>
                      <div>
                        <h5 className="card-title mb-0">
                          Toolbox Meeting List
                        </h5>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div>
                      {(IsValidPermissions(['ADD_TOOLBOX_MEETING'])) &&
                        <Button color="success" onClick={toggleModal}>
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add Toolbox Meeting
                        </Button>
                      }
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={toolboxMeetings?.results || []}
                      isLoading={isFetching}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={page.pageSize}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      theadClass="table-light text-muted"
                      thClass="table-light text-muted"
                      SearchPlaceholder="Search for Toolbox Meeting..."
                      isPagination={false}
                      isServerPagination={true}
                      isToolBoxFilter={true}
                      serverPaginationProps={{
                        count: toolboxMeetings?.count || 0,
                        next: toolboxMeetings?.next || null,
                        page_size: toolboxMeetings?.page_size || 10,
                        previous: toolboxMeetings?.previous || null,
                        current_page: toolboxMeetings?.current_page || 1,
                        total_page: toolboxMeetings?.total_page || 0
                      }}
                      setPageState={setPageState}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>


        {/* Modal for Creating New Hot Work Permit */}
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            Create New ToolBox Meeting
          </ModalHeader>
          <ModalBody>
            <Label for="projectSelect">Project</Label>
            <Input
              type="select"
              id="projectSelect"
              onChange={(e) => setSelectedProject(e.target.value)}
              value={selectedProject || ""}
            >
              <option value="">Select Project</option>
              {projectOptions.map((project: any) => (
                <option key={project.value} value={project.value}>
                  {project.label}
                </option>
              ))}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={handleCreateNewToolBox}
              disabled={!selectedProject}
            >
              Create New ToolBox Meeting
            </Button>
            <Button color="danger" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

      </div>
    </React.Fragment>
  );
};

export default ToolBoxMeetingListView;
