import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";

interface AddWorkLocationModalProps {
  isOpen: boolean;
  toggle: () => void;
  workLocation: string;
  setWorkLocation: (value: string) => void;
  isSubmitting: boolean;
  onSubmit: () => void;
  isPermitFetching: boolean;
}

const AddWorkLocationModal: React.FC<AddWorkLocationModalProps> = ({
  isOpen,
  toggle,
  workLocation,
  setWorkLocation,
  isSubmitting,
  onSubmit,
  isPermitFetching
}) => {
  return (
    <Modal backdrop={'static'} isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Add Work Location</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="workLocation">Work Location</Label>
            <Input
              type="text"
              id="workLocation"
              value={workLocation}
              onChange={(e) => setWorkLocation(e.target.value)}
              placeholder="Enter work location"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSubmit} disabled={isSubmitting || isPermitFetching}>
          {isSubmitting || isPermitFetching ? <Spinner size="sm" /> : "Add"}
        </Button>
        <Button color="secondary" onClick={toggle} disabled={isSubmitting || isPermitFetching}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddWorkLocationModal;
