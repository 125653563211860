import React from "react";
import {
  Badge,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { useGetEmployeeDropDownQuery } from "slices/employee/employeeSlice";
import { useGetProjectsQuery } from "slices/employee/employeeSlice";
import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS } from "helpers/common_helper";
import { formatDateTime } from "helpers/date_utils";
//SimpleBar

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
  pdf: "ri-file-pdf-line", // Use an icon for PDFs
  doc: "ri-file-word-line", // Use an icon for Word docs
  docx: "ri-file-word-line",
  zip: "ri-folder-zip-line", // Use an icon for ZIP files
  rar: "ri-folder-zip-line",
  default: "ri-file-line", // Default icon for unknown file types
};

interface ProjectProps {
  getExcavationPermitById: any;
}

const OverviewTab: React.FC<ProjectProps> = ({ getExcavationPermitById }) => {
  const { data: employeeData } = useGetEmployeeDropDownQuery();
  const { data: projectData } = useGetProjectsQuery();
  const { data: companyData } = useGetCompaniesQuery();

  // Find Supervisor Names from IDs
  const getSupervisorNames = () => {
    return getExcavationPermitById?.name_of_excavation_supervisor.map((id: number) => {
      const supervisor = employeeData?.find((emp: any) => emp.id === id);
      return supervisor ? supervisor.employee_fullname : `Unknown ID (${id})`;
    });
  };

  // Get Company Name
  // const getCompanyName = () => {
  //   const company = companyData?.find(
  //     (comp: any) => comp.id === getExcavationPermitById?.company
  //   );
  //   return company
  //     ? company.company_name
  //     : `Unknown Company ID (${getExcavationPermitById?.company})`;
  // };

  // Get Project Name
  const getProjectName = () => {
    const project = projectData?.find(
      (proj: any) => proj.id === getExcavationPermitById?.project
    );
    return project
      ? project.project_name
      : `Unknown Project ID (${getExcavationPermitById?.project})`;
  };

  // Extract file extension and return corresponding icon or image
  const getFileTypeIconOrImage = (documentUrl: string) => {
    const fileName = documentUrl?.split("/").pop()?.split("?")[0] || "";
    const fileExtension = fileName?.split(".").pop()?.toLowerCase() || "default";

    if (imageExtensions.includes(fileExtension)) {
      // Show the actual image if it's an image file
      return (
        <CardImg
          top
          width="150px"
          height="150px"
          src={documentUrl}
          alt={fileName}
          style={{ position: "relative" }}
        />
      );
    } else {
      // Return appropriate icon for non-image files
      const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <i className={`${iconClass} ri-4x`} />
        </div>
      );
    }
  };

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length > maxLength) {
      const truncatedName = fileName.substring(0, maxLength) + "...";
      return truncatedName;
    }
    return fileName;
  };

  const extractDocumentName = (url: string) => {
    const parts = url?.split("/");
    return parts[parts?.length - 1];
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <CardBody>
              <div className="text-muted">
              <h5 className="mt-2">Permit Site Details</h5>
                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Project Name :
                        </p>
                        <h5 className="fs-15 mb-0">{getProjectName()}</h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Work Supervisor :
                        </p>
                        <h5 className="fs-15 mb-0">
                              <Badge
                                color="info"
                                className="me-2"
                              >
                                {getExcavationPermitById?.work_supervised_by}
                              </Badge>
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                         Work Location :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.work_location}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                         Work Description :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.work_description}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Work Performed By :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.work_performed_by}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Contact Number :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {
                            getExcavationPermitById?.contact_number
                          }
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                    <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Equipment Used :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {
                            getExcavationPermitById?.type_of_excavation_equipment_used
                          }
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                    <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Equipment Operator :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {
                            getExcavationPermitById?.name_of_excavation_equipment_operator	
                          }
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Start Date of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.start_date_of_work}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Start Time of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.start_time_of_work}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          End Date of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.end_date_of_work}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          End Time of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.end_time_of_work}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Submitted Date Time :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {formatDateTime(getExcavationPermitById?.submit_date, 'datetime')}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                        Submission Status :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.report_status_name ?
                            <BadgeStatus value={getExcavationPermitById?.report_status_name} color={STATUS_COLORS[getExcavationPermitById?.report_status_name]} />
                            : null}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Approval Status :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.approval_status_name ?
                            <BadgeStatus value={getExcavationPermitById?.approval_status_name} color={STATUS_COLORS[getExcavationPermitById?.approval_status_name]} />
                            : null}
                        </h5>
                      </div>
                    </Col>  
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={6} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Report Submitted Location :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {getExcavationPermitById?.location}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={6} sm={6}>
                    <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Report Closed Location :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {/* {getExcavationPermitById?.location} */}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={12} lg={12}>
          <Card>
            {/* <CardHeader>
              <h5 className="card-title">Task Title: &nbsp;&nbsp;{projectData?.title}</h5>
            </CardHeader> */}
            <CardBody>
              <h5 className="mt-3">Worker And Site Area Details</h5>
              <hr />
              <Row className="mt-4">
                <Col md={12}>
                  <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Work Location</th>
                        <th scope="col">Employee No</th>
                        <th scope="col">Employee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getExcavationPermitById?.work_area_allocation?.length > 0 ? (
                        getExcavationPermitById.work_area_allocation.map(
                          (workArea: any, index: number) => (
                            <tr key={workArea.id}>
                              <td>{workArea.work_location}</td>
                              <td>
                                {workArea.employee?.map((location: any, index: number) => {
                                  console.log("employee", location)
                                  return (
                                    <tr className="py-2 d-flex">
                                      {location.employee_number}
                                    </tr>
                                  )
                                }
                                )
                                }
                              </td>

                              <td>
                                {workArea.employee?.map((location: any, index: number) => {
                                  return (
                                    <tr className="py-2 d-flex">
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">

                                          <div className="flex-shrink-0 avatar-xs me-2">
                                            <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                              {location.employee_fullname?.charAt(0)}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 ms-2 name">
                                          {location.employee_fullname}
                                        </div>
                                      </div>
                                    </tr>
                                  )
                                }
                                )
                                }
                              </td>

                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No work locations found yet
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl={12} lg={12}>
          <Card>
            {/* <CardHeader>
              <h5 className="card-title">Task Title: &nbsp;&nbsp;{projectData?.title}</h5>
            </CardHeader> */}
            <CardBody>
              {/* Display Questionnaires in Table */}
              <h5>Check List</h5>
              <hr />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {getExcavationPermitById?.questionnaires?.map((q: any) => (
                    <tr key={q.id}>
                      <td>{q.question}</td>
                      <td>
                        <Badge
                          color={q.answer === "Yes" ? "success" : "danger"}
                        >
                          {q.answer}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>

        <Col xl={12} lg={12}>
          <Card>
            {/* <CardHeader>
              <h5 className="card-title">Task Title: &nbsp;&nbsp;{projectData?.title}</h5>
            </CardHeader> */}
            <CardBody>
              {/* Display Documents */}
              <h5>Safety Documents</h5>
              <hr />
              <Row>
                {getExcavationPermitById?.documents?.map(
                  (document: any, index: number) => (
                    <Col key={index} md={3} className="mb-4">
                      <Card>
                        {getFileTypeIconOrImage(document?.attachment_url)}
                        <CardBody>
                          {/* Extract document name from URL */}
                          <CardTitle tag="h5">
                            {truncateFileName(
                              extractDocumentName(document?.attachment)
                            )}
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OverviewTab;
