import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, 
  Input,
  Modal,
  Row,
  UncontrolledDropdown,
  FormGroup,
  FormFeedback,
  Spinner,
  Label,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardTitle,
  Badge

} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  projectSlice,
  useGetTeamByProjectIdQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
} from "slices/projects/projectSlice";
import {
  useGetPositionsQuery,
  useGetEmployeeDropDownByPositionQuery,
  usePatchProjectTeamEmployeeAssignedMutation,
  useDeleteProjectTeamEmployeeAssignedMutation
} from "slices/employee/employeeSlice";
import { team } from "common/data";
import classnames from "classnames";
import Select from "react-select";
import { useDispatch } from "react-redux";


interface ProjectProps {
  projectData: any;
}

interface OptionType {
  value: string;
  label: string;
  id: string;
  name: string;
  project_name: string;
  employee_fullname: string;
}

const MembersTab: React.FC<ProjectProps> = ({ projectData }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedPosition, setSelectedPosition] = useState<string | null>(null);
  const [selectedAssignedTeamId, setSelectedAssignedTeamId] = useState<string | null>(null);
  const [selectedAssignedTeamName, setSelectedAssignedTeamName] = useState<string | null>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<{
    employee_id: number,
    team: number,
    employee_name: string,
    employee_number: string,
    team_name: string
  }[]>([]);

  const { data: positionData } = useGetPositionsQuery(undefined);


  const [verticalTab, setVerticalTab] = useState("ALL");

  const toggleVertical = (tab: any) => {
    if (verticalTab !== tab) {
      setVerticalTab(tab);
    }
    setSelectedRows([]);
  };

  const handleCheckboxChange = (index: number) => {
    setSelectedRows((prev) => {
      const exists = prev.includes(index);
      if (exists) {
        return prev.filter((i) => i !== index); // Remove if already selected
      }
      return [...prev, index]; // Add if not selected
    });
  };

  const { data: teamData,
    isLoading,
    refetch,
  } = useGetTeamByProjectIdQuery(projectData.id, {
    skip: !projectData.id,
  });

  const { data: employeesData, isLoading: employeesDataLoading, isFetching: employeesDataFetching } = useGetEmployeeDropDownByPositionQuery(selectedPosition, {
    skip: selectedPosition === null,
  });

  const [projectTeamEmployeeAssigned, { isLoading: projectteamEmployeeLoading }] = usePatchProjectTeamEmployeeAssignedMutation();
  const [deleteProjectTeamEmployeeAssigned, { isLoading: deleteProjectteamEmployeeLoading }] = useDeleteProjectTeamEmployeeAssignedMutation();

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddTeam = () => {
    setSelectedPosition('');
    setSelectedAssignedTeamId('');
    setSelectedAssignedTeamName('');
    setSelectedEmployees([]);
    setShowPreview(false);
    handleModalToggle();
  };

  const handleDelete = () => {
    const selectedData = selectedRows.map((index) => {
      const { employee_id, team, employee_name, employee_number, team_name } = filteredData[index];
      return {
        employee_id: employee_id,
        team: team,
        employee_name: employee_name,
        employee_number: employee_number,
        team_name: team_name
      };
    });
    console.log("Selected Data:", selectedData);
    setDeleteData(selectedData);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const payload = {
        project_id: projectData.id,
        updatedData: {
          employee_list: deleteData,
        },
      };
      await deleteProjectTeamEmployeeAssigned(payload).unwrap();
      refetch();
      dispatch(projectSlice.util.invalidateTags([{ type: "GetProjectsById" }]));
      setDeleteData([]);
      setSelectedPosition('');
      setSelectedAssignedTeamId('');
      setSelectedAssignedTeamName('');
      setSelectedEmployees([]);
      setSelectedRows([]);
      setDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleAssignedTeamSubmit = async () => {
    console.log('selectedAssignedTeamId', selectedAssignedTeamId);
    console.log('selectedEmployees', selectedEmployees)
    try {
      const payload = {
        project_id: projectData.id,
        updatedData: {
          team: selectedAssignedTeamId,
          employee_list: selectedEmployees,
        },
      };
      await projectTeamEmployeeAssigned(payload).unwrap();
      setSelectedPosition('');
      setSelectedAssignedTeamId('');
      setSelectedAssignedTeamName('');
      setSelectedEmployees([]);
      setShowPreview(false);
      dispatch(projectSlice.util.invalidateTags([{ type: "GetProjectsById" }]));
      handleModalToggle();
    } catch (error) {
      console.error("Error submitting Team/Employee:", error);
    }
  }

  const handlePositionSelectChange = (selectedOption: OptionType | null): void => {
    setSelectedPosition(selectedOption?.value || '')
  };

  const handleTeamSelectChange = (selectedOption: OptionType | null): void => {
    setSelectedAssignedTeamId(selectedOption?.value || '')
    setSelectedAssignedTeamName(selectedOption?.label || '')
  };

  const handleEmployeeCheckboxChange = (employeeId: string) => {
    setSelectedEmployees((prev) =>
      prev.includes(employeeId)
        ? prev.filter((id) => id !== employeeId) // Remove if already selected
        : [...prev, employeeId] // Add if not selected
    );
  };

  const handleSelectAllChange = () => {
    const currentEmployeeIds = employeesData?.map((employee: any) => employee.id) || [];
    if (currentEmployeeIds.every((id) => selectedEmployees.includes(id))) {
      // Deselect all current employees
      setSelectedEmployees((prev) => prev.filter((id) => !currentEmployeeIds.includes(id)));
    } else {
      // Select all current employees
      setSelectedEmployees((prev) => Array.from(new Set([...prev, ...currentEmployeeIds])));
    }
  };

  // Filter the members_list based on the selected tab
  const filteredData =
    verticalTab === "ALL"
      ? projectData.members_list
      : projectData.members_list.filter((member: any) => member.team_name === verticalTab);

  console.log('filteredData', filteredData);
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Team Information</h5>
            <Button color="secondary me-1"
              onClick={handleAddTeam}
            >
              <i className="ri-add-line me-1 align-bottom"></i> Assign Menbers
            </Button>
            {selectedRows.length > 0 && (
              <Button color="danger" onClick={handleDelete}>
                Delete Selected
              </Button>
            )}
          </div>

          <Row>
            <Col md={3}>
              <Nav pills className="flex-column" id="v-pills-tab">
                {/* Default "ALL" Tab */}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ "mb-2": true, active: verticalTab === "ALL" })}
                    onClick={() => toggleVertical("ALL")}
                    id="v-pills-all-tab"
                  >
                    ALL
                  </NavLink>
                </NavItem>

                {/* Dynamically Render Team Tabs */}
                {teamData?.slice().sort((a: any, b: any) => a.id - b.id) // Creates a copy, then sorts
                  .map((team: any) => (
                    <NavItem key={team.id}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ "mb-2": true, active: verticalTab === team.team_name })}
                        onClick={() => toggleVertical(team.team_name)}
                        id={`v-pills-${team.team_name.toLowerCase()}-tab`}
                      >
                        {team.team_name}
                      </NavLink>
                    </NavItem>
                  ))}
              </Nav>
            </Col>

            <Col md={9}>
              <TabContent activeTab={verticalTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="ALL">
                  <h5>All Members</h5>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedRows(filteredData.map((_: any, index: number) => index)); // Select all
                              } else {
                                setSelectedRows([]); // Deselect all
                              }
                            }}
                            checked={selectedRows.length === filteredData.length && filteredData.length > 0}
                          />
                        </th>
                        <th>#</th>
                        <th>Employee Name</th>
                        <th>Employee Number</th>
                        <th>Team Name</th>
                      </tr>
                    </thead>
                    <tbody>
                    {filteredData && filteredData.length > 0 ? (
                      filteredData.map((member: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(index)}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>{member.employee_name}</td>
                          <td>{member.employee_number}</td>
                          <td>{member.team_name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No members are assigned to the Project yet
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </Table>
                </TabPane>

                {/* Dynamically Render Team-Specific Tab Content */}
                {teamData?.map((team: any) => (
                  <TabPane tabId={team.team_name} key={team.id}>
                    <h5>Members in {team.team_name}</h5>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedRows(filteredData.map((_: any, index: number) => index)); // Select all
                                } else {
                                  setSelectedRows([]); // Deselect all
                                }
                              }}
                              checked={selectedRows.length === filteredData.length && filteredData.length > 0}
                            />
                          </th>
                          <th>#</th>
                          <th>Employee Name</th>
                          <th>Employee Number</th>
                        </tr>
                      </thead>
                      <tbody>
                      {filteredData && filteredData.length > 0 ? (
                        filteredData.map((member: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(index)}
                                onChange={() => handleCheckboxChange(index)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{member.employee_name}</td>
                            <td>{member.employee_number}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No members are assigned to the Team yet
                          </td>
                        </tr>
                      )}
                      </tbody>
                    </Table>
                  </TabPane>
                ))}
              </TabContent>
            </Col>
          </Row>


        </CardBody>
      </Card>

      {/* Add/Edit Document Modal */}
      <Modal size={'lg'} backdrop={'static'} isOpen={isModalOpen} toggle={handleModalToggle}>
        <ModalHeader>{'Assign Employee'}</ModalHeader>
        <ModalBody>
          <LoadingOverlay active={isLoading} spinner text="Loading...">
            {!showPreview ? (
              // Form Section
              <form>
                <FormGroup>
                  <Label for="team_name-field" className="form-label">
                    Filter by Position
                  </Label>
                  <Select
                    name="position"
                    options={[
                      { value: '', label: 'Select Position' }, // Option to select none
                      ...(positionData?.map((position) => ({
                        value: position.id.toString(),
                        label: position.position_name,
                      })) || []),
                    ]}
                    onChange={handlePositionSelectChange}
                  />
                </FormGroup>
                <hr />
                <FormGroup>
                  <Label for="team_name-field" className="form-label">
                    Select Team
                  </Label>
                  <Select
                    name="team"
                    options={[
                      { value: '', label: 'Select Team' }, // Option to select none
                      ...(teamData?.map((team: any) => ({
                        value: team.id.toString(),
                        label: team.team_name,
                      })) || []),
                    ]}
                    onChange={handleTeamSelectChange}
                  />
                  {!selectedAssignedTeamId && (<div className="text-danger mt-1">Team is required</div>)}
                </FormGroup>

                {/* Employee Table */}
                <div className="table-responsive mb-3">
                  <Table className="table-borderless align-middle mb-0" bordered>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            checked={
                              (employeesData?.length || 0) > 0 &&
                              employeesData?.every((employee: any) => selectedEmployees.includes(employee.id))
                            }
                            onChange={handleSelectAllChange}
                            disabled={!employeesData?.length}
                          />
                        </th>
                        <th scope="col"># No</th>
                        <th scope="col">Member Name</th>
                        <th scope="col">NRIC</th>
                        <th scope="col">Position</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(employeesDataLoading || employeesDataFetching) ? (
                        <tr>
                          <td colSpan={4} className="text-center mt-5 mb-5">
                            <Spinner color="primary" /> Loading employees...
                          </td>
                        </tr>
                      ) : (
                        employeesData?.map((employee: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedEmployees.includes(employee.id)}
                                onChange={() => handleEmployeeCheckboxChange(employee.id)}
                              />
                            </td>
                            <td>{employee.employee_number}</td>
                            <td>{employee.employee_fullname}</td>
                            <td>{employee.nric_mask}</td>
                            <td><Badge
                              key={index}
                              color="primary"
                              className="me-2"
                            >
                              {employee.position_name}
                            </Badge></td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>

                {/* Preview Button */}
                <Button
                  color="info"
                  type="button"
                  onClick={() => setShowPreview(true)}
                  disabled={selectedEmployees.length === 0 || !selectedAssignedTeamId}
                >
                  Preview
                </Button>
                &nbsp;&nbsp;

                {/* Cancel Button */}
                <Button
                  color="secondary"
                  onClick={() => {
                    handleModalToggle();
                    setSelectedEmployees([]);
                  }}
                >
                  Cancel
                </Button>
              </form>
            ) : (
              // Preview Section
              <div>
                <h5 className="mb-3">Preview Selected Employees</h5>
                <h5 className="mb-3">Team Name: {selectedAssignedTeamName}</h5>
                <Table className="table-borderless align-middle" bordered>
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Member Name</th>
                      <th scope="col">Number</th>
                      <th scope="col">Position</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedEmployees.map((employeeId) => {
                      const employee = employeesData?.find((e: any) => e.id === employeeId);
                      return employee ? (
                        <tr key={employee.id}>
                          <td>{employee.employee_fullname}</td>
                          <td>{employee.employee_number}</td>
                          <td>{employee.position_name}</td>
                        </tr>
                      ) : null;
                    })}
                  </tbody>
                </Table>

                {/* Confirm and Back Buttons */}
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleAssignedTeamSubmit}
                  disabled={(selectedEmployees.length === 0) || projectteamEmployeeLoading}
                >
                  {projectteamEmployeeLoading ? <Spinner size="sm" /> : 'Confirm Submit'}
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="secondary"
                  onClick={() => setShowPreview(false)}
                  disabled={(selectedEmployees.length === 0) || projectteamEmployeeLoading}
                >
                  Back
                </Button>
              </div>
            )}
          </LoadingOverlay>
        </ModalBody>
      </Modal>


      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
      >
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          <div>Are you sure you want to remove selected employees?</div>

          <Table className="table-borderless align-middle mb-0 mt-3">
            <thead className="table-light">
              <tr>
                <th scope="col"># No</th>
                <th scope="col">Member Name</th>
                <th scope="col">Team</th>
              </tr>
            </thead>
            <tbody>

              {deleteData?.map((employee: any, index: number) => (
                <tr key={index}>
                  <td>{employee.employee_number}</td>
                  <td>{employee.employee_name}</td>
                  <td>{employee.team_name}</td>
                </tr>
              ))
              }

            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            disabled={deleteProjectteamEmployeeLoading}
            onClick={() => setDeleteModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={handleConfirmDelete}
            disabled={deleteProjectteamEmployeeLoading}
          >
            {deleteProjectteamEmployeeLoading ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>

    </React.Fragment>
  );
};

export default MembersTab;
