import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { usePostLoginMutation } from "slices/auth/login/loginSlice";

import logoLight from "../../assets/images/puretech-logo-md.png";

const Login = (props: any) => {

  const [ userPostAction, {isLoading} ] = usePostLoginMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);
  
  useEffect(() => {
    sessionStorage.clear();
  }, [])
  
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      setGeneralError("");
      validation.setFieldError("username", "");
      validation.setFieldError("password", "");

      const data: any = await userPostAction({
        username: values.username,
        password: values.password,
      });

      if(data.error && data.error.status === 400){
        const errors = data.error.data.errors;

        const userNameDoesNotExist = errors.filter((err: any)=>{
          return err.detail.toLowerCase().includes("username does not exist");
        })
        if (userNameDoesNotExist && userNameDoesNotExist.length > 0){
          validation.setFieldError("username", userNameDoesNotExist[0].detail);
        }
        
        const passwordDoesNotExist = errors.filter((err: any)=>{
          return err.detail.toLowerCase().includes("password is incorrect");
        })
        if (passwordDoesNotExist && passwordDoesNotExist.length > 0){
          validation.setFieldError("password", passwordDoesNotExist[0].detail);
        }
      }

      if (data.data) {
            var finallogin: any = JSON.stringify(data.data);
            finallogin = JSON.parse(finallogin);
      
            const role_id = finallogin.role_id;
            const role_name = finallogin.role_name;
      
            const {
              refresh,
              access,
              username,
              user_id,
              employee_id,
              email,
              employee_uuid,
              employee_full_name,
            } = finallogin;

            const history = props.router.navigate;
      
            if (role_id.length > 1) {
              // More than one role: navigate to role selection page
              history("/role-select", {
                state: { roles: role_name, roleIds: role_id, login: finallogin },
              });
            } else if (role_id.length === 1) {
              // Only one role: proceed to dashboard
              history("/dashboard");
              sessionStorage.setItem(
                "selectedRole",
                JSON.stringify({ role_id: role_id[0], role_name: role_name[0] })
              );
              localStorage.setItem(
                "selectedRole",
                JSON.stringify({ role_id: role_id[0], role_name: role_name[0] })
              );
              // Storing user information
              localStorage.setItem(
                "authUser",
                JSON.stringify({
                  refresh,
                  access,
                  username,
                  user_id,
                  employee_id,
                  email,
                  employee_uuid,
                  employee_full_name,
                })
              );
      
              sessionStorage.setItem("authUser", JSON.stringify(finallogin));
            } else {
              const error = "Members role not assigned";
              setGeneralError(error);
              throw new Error(error);
            }
          } else {
            const error = "No data returned from login.";
            setGeneralError(error);
            throw new Error(error);
          }
    },
  });

  document.title = "SignIn User";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="60" />
                    </Link>
                  </div>
                  {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to PureTech.
                        {generalError && (
                          <Alert color="danger">{generalError}</Alert>
                        )}
                      </p>
                    </div>
                    {/* {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null} */}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="username" className="form-label">
                            Username
                          </Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter Username"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={showPassword ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                              onClick={() => setShowPassword(!showPassword)}
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={isLoading}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {isLoading && (
                              <Spinner size="sm" className="me-2"></Spinner>
                            )}
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                {/* <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
