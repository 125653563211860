import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import Step1ToolBoxDetails from "./WizardSteps/Step1ToolBoxDetails";
import Step2ToolBoxAttendance from "./WizardSteps/Step2ToolBoxAttendance";
import ValidationAlertStatic from "../../ValidationAlertStatic";
import { getErrorText } from "../../../../helpers/error_message";
import { safetyAppSlice } from "slices/safetyApp/safetyappSlice";
import { useCheckToolBoxStatusMutation } from "slices/workflow/WorkFlowSlice";

const ToolBoxMeetingWizard: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const { selectedProject, toolBoxId } = useParams<{ selectedProject: string, toolBoxId: string }>();;
  const [location, setLocation] = useState<{ latitude: number | null; longitude: number | null }>({ latitude: null, longitude: null });
  const [modalBackDrop, setModalBackDrop] = useState<boolean>(false);
  const [geoPermission, setGeoPermission] = React.useState<boolean>(false);

  const [checkToolboxMeeting] = useCheckToolBoxStatusMutation();

  console.log('selectedProject', selectedProject)

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      if (toolBoxId) {
        dispatch(safetyAppSlice.util.invalidateTags([{ type: "ToolboxMeeting", id: toolBoxId }]));
      }
      setActiveTab(tab);
    }
  };

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setGeoPermission(true);
        },
        (err) => {
          setValidationMessages((prev) => {
            const errorMessage = getErrorText('location-disabled');
            return prev.includes(errorMessage)
              ? prev
              : [...prev, errorMessage]
          });
          setModalBackDrop(true);
          setGeoPermission(false);
        },
        { enableHighAccuracy: true }
      );
    } else {
      setValidationMessages((prev) => {
        const errorMessage = getErrorText('location-not-supported');
        return prev.includes(errorMessage)
          ? prev
          : [...prev, errorMessage]
      });
      setModalBackDrop(true);
      setGeoPermission(false);
    }
  };

  const validateToolBoxMeeting = async () => {
    const checkStatus: any = await checkToolboxMeeting({projectId: selectedProject});
    console.log(checkStatus);

    if (checkStatus.data.length !== 0) {
      const toolboxId  = checkStatus.data[0].toolbox_id;
      const permitStatus = checkStatus.data[0].report_status;
      console.log('toolBoxId', toolBoxId)
      console.log('toolBoxId', toolboxId)
      if ((toolBoxId !== toolboxId) ){
        console.log('vbbb trigger')
        setValidationMessages((prev: any) => {
          // Use a unique identifier to avoid duplicates
          const errorMessageText = `${getErrorText('toolbox-status-exist')}`;
          if (prev.some((message: any) => message.key === errorMessageText)) {
            return prev; // If already exists, return the same list
          }
    
          // Add new error message
          const errorMessage = (
            <span key={errorMessageText}>
              {getErrorText('toolbox-status-exist')} <b>{toolboxId} - {permitStatus}</b>
            </span>
          );
          return [...prev, errorMessage];
        });
    
        setModalBackDrop(true);
      }
    }
  }

  useEffect(() => {
    setValidationMessages([]);
    getLocation();
    validateToolBoxMeeting();
  
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="ToolBox Meeting" breadcrumbItem="Wizard" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody className="form-steps">
                <div className="step-arrow-nav mb-4">
                  <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 1 })}
                      // onClick={() => toggleTab(1)}
                      >
                        ToolBox Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                      // onClick={() => toggleTab(2)}
                      >
                        ToolBox Attendance
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Step1ToolBoxDetails
                      nextStep={() => toggleTab(2)}
                      geoPermission={geoPermission}
                    />
                  </TabPane>
                  <TabPane tabId={2}>
                    <Step2ToolBoxAttendance
                      previousStep={() => toggleTab(1)}
                      location={location}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ValidationAlertStatic
        validationMessages={validationMessages}
        previousPageNavLink="/toolboxmeeting-list" modalBackDrop={modalBackDrop} setModalBackDrop={setModalBackDrop} />
    </div>
  );
};

export default ToolBoxMeetingWizard;
