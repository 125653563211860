export const formatDateTime = (value: string, type: string) => {
    if (!value) return null;

    // Get browser's locale and timezone
    const { locale, timeZone } = Intl.DateTimeFormat().resolvedOptions();
    console.log('timezone-----', locale, timeZone);

    if (type === 'date') {
        const options: any = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(value);
        return date.toLocaleDateString(locale, options);
    }

    if (type === 'time') {
        const [hours, minutes] = value.split(':');
        return `${hours}:${minutes}`;
    }

    if (type === 'datetime') {
        console.log('convert before vale: ', value);
        const date = new Date(value);
        // Correct date and time options
        const dateTimeOptions = {
            day: '2-digit', // "2-digit" is the correct option
            month: 'short', // "short" is the correct option
            year: 'numeric', // "numeric" is the correct option
            hour: '2-digit', // "2-digit" is the correct option
            minute: '2-digit', // "2-digit" is the correct option
            hour12: false, // 24-hour format
            timeZone, // Set the timezone
        };
      
        //@ts-ignore
        const formattedDateTime = new Intl.DateTimeFormat(locale, dateTimeOptions).format(date);
        console.log('convert after vale: ', formattedDateTime);
        return formattedDateTime;
    }
    return value;
}