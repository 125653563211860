import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  Col,
  Row,
  Table,
  Badge
} from "reactstrap";
// import { useGetEmployeeDropDownQuery } from "slices/employee/employeeSlice";
// import { useGetProjectsQuery } from "slices/employee/employeeSlice";
// import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS } from "helpers/common_helper";
import { formatDateTime } from "helpers/date_utils";


interface OverviewProps {
  toolboxMeetingByIdData: any;
}

const OverviewTab: React.FC<OverviewProps> = ({ toolboxMeetingByIdData }) => {

  return (
    <React.Fragment>
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <CardBody>
              <div className="text-muted">
                <h5 className="mt-2">Tool Box Meeting Details</h5>
                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Main/Sub Contractor :
                        </p>
                        <h5 className="fs-15 mb-0">{toolboxMeetingByIdData?.contractor}</h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Project :
                        </p>
                        <h5 className="fs-15 mb-0">{toolboxMeetingByIdData?.project_name}</h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Conducted By :
                        </p>
                        <h5 className="fs-15 mb-0">{toolboxMeetingByIdData?.conducted_by_name}</h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Submission Status :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.report_status_name ?
                            <BadgeStatus value={toolboxMeetingByIdData?.report_status_name} color={STATUS_COLORS[toolboxMeetingByIdData?.report_status_name]} />
                            : null}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Start Date of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {formatDateTime(toolboxMeetingByIdData?.start_date_of_work, 'date')}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Start Time of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {formatDateTime(toolboxMeetingByIdData?.start_time_of_work, 'time')}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          End Date of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {formatDateTime(toolboxMeetingByIdData?.end_date_of_work, 'date')}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          End Time of Work :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {formatDateTime(toolboxMeetingByIdData?.end_time_of_work, 'time')}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                  <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Submitted By :
                        </p>
                        <h5 className="fs-15 mb-0">{toolboxMeetingByIdData?.submited_by_name}</h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Submitted Date Time :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {formatDateTime(toolboxMeetingByIdData?.submit_date, 'datetime')}
                        </h5>
                      </div>
                    </Col>
                    
                    <Col lg={6} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Submitted Location :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.location}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                {toolboxMeetingByIdData?.toolbox_comments ?
                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={6} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                        Work Description :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.work_description}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={6} sm={12}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Comments :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.toolbox_comments}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                : ""}
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={12} lg={12}>
          <Card>
            {/* <CardHeader>
              <h5 className="card-title">Task Title: &nbsp;&nbsp;{projectData?.title}</h5>
            </CardHeader> */}
            <CardBody>
              <h5 className="mt-2">Tool Box Attendance Details</h5>
              <hr />
              <Row className="mt-4">
                <Col md={12}>
                  <Table className="table-borderless align-middle mb-0 table-responsive" responsive>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">#No</th>
                        <th scope="col">Emp #No</th>
                        <th scope="col">NRIC/FIN</th>
                        <th scope="col">Name</th>
                        <th scope="col">Designation</th>
                        {toolboxMeetingByIdData?.health_declaration?.map((declaration: any, index: number) => (
                          <th scope="col" key={index} style={{minWidth: 150, textAlign: "center"}}>
                            {formatDateTime(declaration?.declaration_date, 'datetime')}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {toolboxMeetingByIdData?.toolbox_attendance?.length > 0 ? (
                        toolboxMeetingByIdData.toolbox_attendance.map(
                          (attendance: any, index: number) => {
                            // Find matching health statuses for the current employee
                            const employeeStatuses = toolboxMeetingByIdData.health_declaration.map(
                              (declaration: any) =>
                                declaration.statuses.find(
                                  (status: any) => status.employee_number === attendance?.employee?.employee_number
                                )
                            );

                            console.log('employeeStatuses', employeeStatuses)
                            return <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{attendance?.employee?.employee_number}</td>
                              <td>{attendance?.employee?.nric_mask}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  {attendance?.employee.employee_profile_url ?
                                    <div className="flex-shrink-0">
                                      <img src={attendance?.employee.employee_profile_url} alt="" className="avatar-xs rounded-circle" />
                                    </div> :
                                    <div className="flex-shrink-0 avatar-xs">
                                      <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                        {attendance?.employee.employee_fullname.charAt(0)}
                                      </div>
                                    </div>}

                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">{attendance?.employee.employee_fullname}</h6>

                                  </div>

                                </div>
                              </td>
                              <td>{attendance?.employee.position_name}</td>

                              {/* Render health statuses */}
                              {employeeStatuses.map((status: any, idx: number) => (
                                <td key={idx} className="text-center">
                                  {status?.health_status === 1 ? <Badge color="success fs-11 text-uppercase">Fit</Badge>: <Badge color="danger fs-11 text-uppercase">Not Fit</Badge>}
                                </td>
                              ))}
                            </tr>
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No employee attendance added yet
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </React.Fragment>
  );
};

export default OverviewTab;
