import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import ActiveProjects from './ActiveProjects';
import Chat from './Chat';
import MyTasks from './MyTasks';
import ProjectsOverview from './ProjectsOverview';
import ProjectsStatus from './ProjectsStatus';
import TeamMembers from './TeamMembers';
import UpcomingSchedules from './UpcomingSchedules';
import Widgets from './Widgets';
import PermitDashboard  from "./PermitCard"
import ActiveReportSummary from './ActiveReportSummary';
import ActiveToolboxSummary from './ActiveToolboxSummary';

const AdminDashboard = () => {
    document.title="Admin Dashboard";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Projects" pageTitle="Dashboards" />
                    <Row className="project-wrapper">
                        <Col xxl={8}>
                            <Widgets />
                            {/* <ProjectsOverview /> */}
                        </Col>
                        {/* <UpcomingSchedules /> */}
                    </Row>
                    <Row>
                        
                        
                        <ActiveReportSummary/>
                        <ActiveToolboxSummary/>
                        
                        <ActiveProjects />
                        <TeamMembers />
                       
                    </Row>
                    {/* <Row>
                        <TeamMembers />
                        <PermitDashboard />
                        <Chat />
                         <MyTasks />
                        <ProjectsStatus />
                    </Row> */}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AdminDashboard;