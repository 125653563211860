import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Loader from "Components/Common/Loader";
import TableContainer from "Components/Common/TableContainer";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS } from "helpers/common_helper";
import {
  useGetReportSummaryQuery,
} from "slices/dashboardProject/dashboardSlice";
import { generateApprovalUrl } from "helpers/common_helper";

const ActiveReportSummary = () => {
  const {
    data: reportSummary,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
    // refetch: refetchProject,
    // error: projectError,
  } = useGetReportSummaryQuery(undefined);

  const columns = useMemo(
    () => [
      {
        Header: "Permit No",
        accessor: "permit_no",
        filterable: false,
        Cell: ({
          row: {
            original: { permit_no },
          },
        }: any) => {
          const url = generateApprovalUrl(permit_no);
          return <Link
            to={`/${url}/${permit_no}?approval=true`}
            className="text-primary d-inline-block edit-item-btn view-task-status-class"
          >
            {permit_no}
          </Link>
        },
      },
      {
        Header: "Report Status",
        accessor: "report_status_name",
        Cell: (cell: { value: any }) => (
          <>
            {cell.value ?
              <BadgeStatus value={cell.value} color={STATUS_COLORS[cell.value]} />
              : null}
          </>
        ),
      },
      {
        Header: "Type",
        accessor: "report_type",
        filterable: false,
        Cell: (cell: { value: any }) => (
          <>
            {cell.value
              .replace(/_/g, " ")
              .toLowerCase()
              .replace(/\b\w/g, (char: any) => char.toUpperCase())}
          </>
        )
      },
      {
        Header: "Supervised By",
        accessor: "work_supervised_by",
        filterable: false,
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Col xl={6}>
        <Card className='card-height-100'>
          <CardHeader className="d-flex align-items-center">
            <h4 className="card-title flex-grow-1 mb-0">Open Permits</h4>
            {/* <div className="flex-shrink-0">
                            <Link to="#" className="btn btn-soft-info btn-sm shadow-none">Export Report</Link>
                        </div> */}
          </CardHeader>
          <CardBody className='min-width-height-400'>
            <div className="table-responsive table-card">
              {isProjectLoading ? (
                <div className='mt-5'><Loader /></div>
              ) : (
                <TableContainer
                  columns={columns}
                  data={reportSummary || []}
                  isLoading={isProjectFetching}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customPageSize={50}
                  className="custom-header-css"
                  divClass="table table-nowrap table-centered align-middle"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light text-muted"
                  thClass="table-light text-muted"
                  SearchPlaceholder="Search for Projects..."
                  isPagination={false}
                  isServerPagination={false}
                />)}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ActiveReportSummary;