export const getErrorText = (key: string) => {
    const errorText = {
        'location-disabled': 'Please enable location access in your web browser.',
        'location-not-supported': 'Geolocation is not supported by your browser.',
        'workflow-not-assigned': 'Workflow not assigned to Project',
        'report-status-exist': 'Report Already Exist',
        'toolbox-status-exist': 'ToolBox Meeting Already Exist'
    };
    return errorText[key as keyof typeof errorText] || 'An unknown error occurred.';
};
