import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from "react-table";
import { Table, Row, Col, Button, CardBody } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
// import {
//   ProductsGlobalFilter,
//   CustomersGlobalFilter,
//   OrderGlobalFilter,
//   ContactsGlobalFilter,
//   CompaniesGlobalFilter,
//   LeadsGlobalFilter,
//   CryptoOrdersGlobalFilter,
//   InvoiceListGlobalSearch,
//   TicketsListGlobalFilter,
//   NFTRankingGlobalFilter,
//   TaskListGlobalFilter
// } from "../../Components/Common/GlobalSearchFilter";

import { TaskListGlobalFilter } from "../../Components/CommonFilters/TaskFilter";
import { PermitListGlobalFilter } from "Components/CommonFilters/PermitFilter";
import { ToolBoxListGlobalFilter } from "Components/CommonFilters/ToolBoxFilter";
import { Link } from "react-router-dom";
import Loader from "Components/Common/Loader";

interface GlobaFilerPropes {
  preGlobalFilteredRows?: any;
  globalFilter?: any;
  setGlobalFilter?: any;
  setPageState?: any;
  isCustomerFilter?: any;
  isOrderFilter?: any;
  isContactsFilter?: any;
  isCompaniesFilter?: any;
  isCryptoOrdersFilter?: any;
  isInvoiceListFilter?: any;
  isTicketsListFilter?: any;
  isNFTRankingFilter?: any;
  isTaskListFilter?: any;
  isProductsFilter?: any;
  isLeadsFilter?: any;
  SearchPlaceholder?: any;
  isPermitFilter?:any;
  isToolBoxFilter?:any;
}

export interface ServerPaginationType { 
  count: number;
  next: string | null,
  previous: string | null,
  current_page: number,
  page_size: number,
  total_page: number
}

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  setPageState,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isPermitFilter,
  isToolBoxFilter,
  isProductsFilter,
  isLeadsFilter,
  SearchPlaceholder,
}: GlobaFilerPropes) {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
    if (setPageState){
      setPageState((prev: any) => ({
        ...prev,
        search: value
      }));
    }
  }, 200);

  return (
    <React.Fragment>
      <CardBody className="border border-dashed border-end-0 border-start-0 pb-0">
        <form>
          <Row className="g-3">
            <Col>
              <div className={(isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter) ? "search-box me-2 mb-2 d-inline-block" : "search-box me-2 mb-2 d-inline-block col-12"}>
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control search /"
                  placeholder={SearchPlaceholder}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            {/* {isProductsFilter &&  (
              <ProductsGlobalFilter />
            )} */}
            {/* {isCustomerFilter && (
              <CustomersGlobalFilter />
            )} */}
            {/* {isOrderFilter && (
              <OrderGlobalFilter />
            )} */}
            {/* {isContactsFilter && (
              <ContactsGlobalFilter />
            )} */}
            {/* {isCompaniesFilter && (
              <CompaniesGlobalFilter />
            )} */}
            {/* {isLeadsFilter && (
              <LeadsGlobalFilter />
            )} */}
            {/* {isCryptoOrdersFilter && (
              <CryptoOrdersGlobalFilter />
            )} */}
            {/* {isInvoiceListFilter && (
              <InvoiceListGlobalSearch />
            )} */}
            {/* {isTicketsListFilter && (
              <TicketsListGlobalFilter />
            )} */}
            {/* {isNFTRankingFilter && (
              <NFTRankingGlobalFilter />
            )} */}
            {isTaskListFilter && (
              <TaskListGlobalFilter setPageState={setPageState} globalValue={setValue}/>
            )}
            {isPermitFilter &&(
              <PermitListGlobalFilter setPageState={setPageState} globalValue={setValue}/>
            )}
            {isToolBoxFilter&&(
              <ToolBoxListGlobalFilter setPageState={setPageState} globalValue={setValue}/>
            )}
          </Row>
        </form>
      </CardBody>

    </React.Fragment>
  );
}


interface TableContainerProps {
  className?: any;
  columns?: any;
  data?: any;
  isGlobalSearch?: any;
  isGlobalFilter?: any;
  isProductsFilter?: any;
  isCustomerFilter?: any;
  isOrderFilter?: any;
  isContactsFilter?: any;
  isCompaniesFilter?: any;
  isLeadsFilter?: any;
  isCryptoOrdersFilter?: any;
  isInvoiceListFilter?: any;
  isTicketsListFilter?: any;
  isNFTRankingFilter?: any;
  isTaskListFilter?: any;
  isPermitFilter?:any;
  isToolBoxFilter?:any;
  isAddOptions?: any;
  isAddUserList?: any;
  handleOrderClicks?: any;
  handleUserClick?: any;
  handleCustomerClick?: any;
  handleTaskClick?: any;
  isAddCustList?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  handleLeadClick?: any;
  handleCompanyClick?: any;
  handleContactClick?: any;
  handleTicketClick?: any;
  handleProductClicks?: any;
  isAddProducts?: any;
  PaginationClass? : any;
  isPagination?: any;
  isServerPagination?: any;
  isLoading?: boolean;
  serverPaginationProps?: ServerPaginationType,
  setPageState?: any;
}

const TableContainer = ({
  columns,
  data,
  isGlobalSearch,
  isGlobalFilter,
  isProductsFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isLeadsFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isPermitFilter,
  isToolBoxFilter,
  handleProductClicks,
  isAddUserList,
  isAddProducts,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  isAddOptions,
  thClass,
  divClass,
  SearchPlaceholder,
  PaginationClass,
  isPagination,
  isServerPagination,
  serverPaginationProps,
  setPageState,
  isLoading = false,
} : TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [],
      },
      manualPagination: isServerPagination ? true : false,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generatePageNumbers = (currentPage: number, totalPages: number) => {
    const pages = [];
  
    // Always show the first page
    if (totalPages > 1) {
      pages.push(1);
    }
  
    // Show ellipsis before current page if it's far enough from the first
    if (currentPage > 3) {
      pages.push('...');
    }
  
    // Show a few pages around the current page
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
      pages.push(i);
    }
  
    // Show ellipsis after current page if it's far enough from the last
    if (currentPage < totalPages - 2) {
      pages.push('...');
    }
  
    // Always show the last page
    if (totalPages > 1) {
      pages.push(totalPages);
    }
  
    return pages;
  };

  const generateSortingIndicator = (column : any) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event : any) => {
    setPageSize(Number(event.target.value));
  };

  // passed to the callback provided by the parent
  const [currentPage, setCurrentPage] = useState(serverPaginationProps?.current_page || 1);
  const pageNumbers = generatePageNumbers(currentPage, serverPaginationProps?.total_page || 1);

  // Onclick handlers for the butons
  const onNextPage = () => {
    if (currentPage < (serverPaginationProps?.total_page || 1)) {
      const nextCount = currentPage + 1;
      setCurrentPage(nextCount);
      gotoPage(nextCount);
      setPageState((prev: any) => ({
        ...prev,
        page: nextCount
      }));
    }
  };
  const onPrevPage = () => {
    if (currentPage > 1 ) {
      const previousCount = currentPage - 1;
      setCurrentPage(previousCount);
      setPageState((prev: any) => ({
        ...prev,
        page: previousCount
      }));
    }
  };
  const onPageSelect = (pageNo: number) => {
    setCurrentPage(pageNo);
    setPageState((prev: any) => ({
      ...prev,
      page: pageNo
    }));
  };

  return (
    <Fragment>
      <Row className="">
        {isGlobalSearch && (
          <Col md={1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setPageState={setPageState}
            isProductsFilter={isProductsFilter}
            isCustomerFilter={isCustomerFilter}
            isOrderFilter={isOrderFilter}
            isContactsFilter={isContactsFilter}
            isCompaniesFilter={isCompaniesFilter}
            isLeadsFilter={isLeadsFilter}
            isCryptoOrdersFilter={isCryptoOrdersFilter}
            isInvoiceListFilter={isInvoiceListFilter}
            isTicketsListFilter={isTicketsListFilter}
            isNFTRankingFilter={isNFTRankingFilter}
            isTaskListFilter={isTaskListFilter}
            SearchPlaceholder={SearchPlaceholder}
            isPermitFilter={isPermitFilter}
            isToolBoxFilter={isToolBoxFilter}
          />
        )}
        {/* {isAddProducts && (
          <div className="col-sm-auto ms-auto">
            <div>
            <Button
              type="button"
              color="secondary"
              className="btn btn-secondary"
              onClick={handleProductClicks}
            >
              <i className="ri-add-line align-bottom me-1"></i> Add
              Product
            </Button>
            </div>
          </div>
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="rounded-pill  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="rounded-pill mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )} */}
      </Row>


      <div className={divClass} style={{marginTop: 0}}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup : any) => (
              <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column : any) => (
                  <th key={column.id} className={thClass} {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {isLoading ? 
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={columns.length}>
                <React.Fragment>
                    <div className="d-flex justify-content-center mx-2 mt-2">
                        <Loader/>
                    </div>
                </React.Fragment>
                </td>
              </tr>
              : page.length === 0 ?
							<tr>
								<td style={{ textAlign: 'center' }} colSpan={columns.length}>No records found</td>
							</tr> 
              : page.map((row : any) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell : any) => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </Table>
      </div>
      {
        (!isLoading && isPagination) && (
          <Row className={PaginationClass} id="pagination-element">
            <div className="col-sm">
              <div className="text-muted">Showing<span className="fw-semibold ms-1">{page.length}</span> of <span className="fw-semibold">{data.length}</span> Results
              </div>
            </div>
            <div className="col-sm-auto ">
              <div className="pagination-wrap hstack gap-2">
                <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                  <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
                    <Link to="#" className="page-link pagination-prev" onClick={previousPage}>Previous</Link>
                  </li>
                  {pageOptions.map((item: any, key: number) => (
                    <React.Fragment key={key}>
                      <li className="page-item">
                        <Link to="#" className={pageIndex === item ? "page-link active" : "page-link"} onClick={() => gotoPage(item)}>{item + 1}</Link>
                      </li>
                    </React.Fragment>
                  ))}
                  <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                    <Link to="#" className="page-link pagination-next" onClick={nextPage}>Next</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        )
      }

      {
        (!isLoading && isServerPagination) && (
          <Row className={PaginationClass} id="pagination-element">
            <div className="col-sm">
              <div className="text-muted">Showing page<span className="fw-semibold ms-1">{currentPage}</span> of <span className="fw-semibold">{serverPaginationProps?.total_page}</span></div>
              <div className="text-muted mt-1"><span className="mt-5">Total records: {serverPaginationProps?.count}</span></div>
            </div>
            <div className="col-sm-auto ">
              <div className="pagination-wrap hstack gap-2">
                <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                  <li className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
                    <Link to="#" className="page-link pagination-prev" onClick={onPrevPage}>Previous</Link>
                  </li>
                  {pageNumbers.map((page, index) => (
                    <React.Fragment key={index}>
                      <li className={`page-item ${page === currentPage ? "active" : ""}`}>
                        {page === '...' ? (
                          <span className="page-link">...</span>
                        ) : (
                          <Link to="#" className="page-link" onClick={() => onPageSelect(Number(page))}>{page}</Link>
                        )}
                      </li>
                    </React.Fragment>
                  ))}
                  <li className={currentPage >= (serverPaginationProps?.total_page || 1) ? "page-item disabled" : "page-item"}>
                    <Link to="#" className="page-link pagination-next" onClick={onNextPage}>Next</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        )
      }
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;