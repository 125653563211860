import React, { useState } from 'react';
import {
    Alert,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import { Link } from 'react-router-dom';


interface ValidationAlert {
    modalBackDrop: boolean;
    setModalBackDrop: (value: boolean) => void;
    previousPageNavLink?: string;
    validationMessages: string[];
    closeButton?: boolean;
}

const ValidationAlertStatic: React.FC<ValidationAlert> = ({ modalBackDrop, setModalBackDrop, previousPageNavLink, validationMessages, closeButton=false }) => {
    function tog_backdrop() {
        setModalBackDrop(!modalBackDrop);
    }

    return (
        <>
            <Modal
                isOpen={modalBackDrop}
                toggle={() => {
                    tog_backdrop();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                // centered
                size="md"
            >
                <ModalHeader className="card-header">
                    <h4 className="modal-title" id="staticBackdropLabel">
                        <i className="ri-error-warning-line me-2 align-middle fs-18 text-danger"></i>
                        <strong>Validation Failed!</strong>
                    </h4>
                </ModalHeader>
                <ModalBody className="text-center p-2 mt-3">
                    <h5 className="text-danger">The following constraints were not met:</h5>

                    <div className="mt-4">
                        {validationMessages.map((message, index) => (
                            <Alert key={index} color="warning" className="material-shadow">
                                {message}
                            </Alert>
                        ))}
                        <div className="hstack gap-2 justify-content-center">
                            {previousPageNavLink &&
                            <Link to={previousPageNavLink} className="btn btn-secondary fw-medium">
                                <i className="ri-close-line me-1 align-middle"></i> Back to the Previous Page
                            </Link>
                            }
                            {closeButton && <Button color='secondary' size='xs' onClick={tog_backdrop}>Close</Button>}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ValidationAlertStatic;
