import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import classnames from "classnames";

//import images
import Loader from "../../../Components/Common/Loader";
import OverviewTab from "./OverviewTab";
import TeamTab from "./TeamTab";
import WorkflowSettingTab from "./WorkflowSettingTab";
import { useParams } from "react-router-dom";
import { useGetprojectByIdQuery } from "slices/projects/projectSlice";
import {
  useGetSafetyAppAssignedWorkflowQuery
} from "slices/workflow/WorkFlowSlice";
import ProjectDocumentList from "../Documents/ProjectDocuments";
import { useGetAuditLogsQuery } from "slices/employee/employeeSlice";
import ActivitiesTab from "Components/Common/ActivitesTab";
import MembersTab from "./MembersTab";

const ProjectOverview = () => {
  const { project_id } = useParams<{ project_id: string }>();
  const {
    data: projectData,
    isLoading: projectLoading,
    refetch: refetchProject,
    // error: taskError,
  } = useGetprojectByIdQuery(project_id);

  const {
    data: assignedWorkflowData,
    isLoading: assignedWorkflowLoading,
    // refetch: refetchAssignedWorkflow,
    // error: assignedWorkflowError,
  } = useGetSafetyAppAssignedWorkflowQuery(project_id);

  const { data: auditLogs, isLoading: auditLogsLoading } = useGetAuditLogsQuery(
    { model_name: "project", object_id: project_id }
  );

  useEffect(() => {
    if (project_id) {
      refetchProject();
    }
  }, [project_id, refetchProject]);

  //Tab

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <React.Fragment>
      {!projectLoading ?
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4">
                <div className="bg-warning-subtle">
                  <CardBody className="pb-0 px-4">
                    <Row className="mb-3">
                      <div className="col-md">
                        <Row className="align-items-center g-3">
                          <div className="col-md-auto">
                            <div className="avatar-md">
                              <div className="avatar-title bg-white rounded-circle">
                                <img
                                  src={projectData?.thumbnail_url}
                                  alt=""
                                  className="avatar-xs"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <div>
                              <h4 className="fw-bold">
                                {projectData?.project_name}
                              </h4>
                              <div className="hstack gap-3 flex-wrap">
                                <div>
                                  <i className="ri-building-line align-bottom me-1"></i>{" "}
                                  {projectData?.project_short_name}
                                </div>
                                <div className="vr"></div>
                                <div>
                                  Start Date :{" "}
                                  <span className="fw-medium">
                                    {projectData?.start_date}
                                  </span>
                                </div>
                                <div className="vr"></div>
                                <div>
                                  End Date :{" "}
                                  <span className="fw-medium">
                                    {projectData?.end_date}
                                  </span>
                                </div>
                                <div className="vr"></div>
                                <div className="badge rounded-pill bg-danger fs-12">
                                  {projectData?.status_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                      <div className="col-md-auto">
                        {/* <div className="hstack gap-1 flex-wrap">
                          <button
                            type="button"
                            className="btn py-0 fs-16 favourite-btn active shadow-none"
                          >
                            <i className="ri-star-fill"></i>
                          </button>
                          <button
                            type="button"
                            className="btn py-0 fs-16 text-body shadow-none"
                          >
                            <i className="ri-share-line"></i>
                          </button>
                          <button
                            type="button"
                            className="btn py-0 fs-16 text-body shadow-none"
                          >
                            <i className="ri-flag-line"></i>
                          </button>
                        </div> */}
                      </div>
                    </Row>

                    <Nav
                      className="nav-tabs-custom border-bottom-0"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "1" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("1");
                          }}
                          href="#"
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "2" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("2");
                          }}
                          href="#"
                        >
                          Documents
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "3" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("3");
                          }}
                          href="#"
                        >
                          Activities
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "4" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("4");
                          }}
                          href="#"
                        >
                          Team
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "5" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("5");
                          }}
                          href="#"
                        >
                          Members
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "6" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("6");
                          }}
                          href="#"
                        >
                          Workflow Config
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1">
                  <OverviewTab projectData={projectData} />
                </TabPane>
                <TabPane tabId="2">
                  {/* <DocumentsTab /> */}
                  <ProjectDocumentList project_id={project_id} />
                </TabPane>
                <TabPane tabId="3">
                  <ActivitiesTab
                    activities={auditLogs}
                    isLoading={auditLogsLoading}
                  />
                </TabPane>
                <TabPane tabId="4">
                  <TeamTab projectData={projectData} />
                </TabPane>
                <TabPane tabId="5">
                  <MembersTab projectData={projectData} />
                </TabPane>
                <TabPane tabId="6">
                  <WorkflowSettingTab workflowData={assignedWorkflowData} workflowLoading={assignedWorkflowLoading} projectId={project_id} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      : <Loader />}
    </React.Fragment>
  );
};

export default ProjectOverview;
