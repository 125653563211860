import React, { useState } from "react";
import { Col, Row, Table, Button, Spinner } from "reactstrap";
import { useGetEmployeeDropDownByProjectQuery } from "slices/employee/employeeSlice";
import { useParams } from "react-router-dom";
import AddWorkLocationModal from "../SteperModals/AddWorkLocationModal";
import AddEmployeeModal from "../SteperModals/AddEmployeeModal";
import WorkerDeleteConfirmationModal from "../SteperModals/WorkerDeleteConfirmationModal";
import AddMultipleEmployeeModal from '../../CommonWizardSteps/SteperModals/AddMultipleEmployeeModal';

interface Step3WorkAreaAllocationProps {
  previousStep: () => void;
  nextStep: () => void;
  permitData: any;
  handleAddWorkLocation: any;
  handleAddEmployeeToWorkArea: any;
  handleDeleteWorkLocationOrEmployee: any;
  isUpdateLoading: any;
  isDeleteLoading: any;

  setIsAddModalOpen: any;
  setIsEmployeeModalOpen: any;
  setIsDeleteModalOpen: any;
  setSelectedId: any;
  setDeleteType: any;
  setSelectedEmployee: any;
  setWorkLocation: any;
  isAddModalOpen: any;
  isEmployeeModalOpen: any;
  isDeleteModalOpen: any;
  workLocation: any;
  selectedEmployee: any;
  isPermitFetching: any
}

const Step3WorkAreaAllocation: React.FC<Step3WorkAreaAllocationProps> = ({
  previousStep,
  nextStep,
  permitData,
  handleAddWorkLocation,
  handleAddEmployeeToWorkArea,
  handleDeleteWorkLocationOrEmployee,
  isUpdateLoading,
  isDeleteLoading,
  setIsAddModalOpen,
  setIsEmployeeModalOpen,
  setIsDeleteModalOpen,
  setSelectedId,
  setDeleteType,
  setSelectedEmployee,
  setWorkLocation,
  isAddModalOpen,
  isEmployeeModalOpen,
  isDeleteModalOpen,
  workLocation,
  selectedEmployee,
  isPermitFetching
}) => {
  const { selectedProject: urlProjectId } = useParams<{ selectedProject: string }>();

  const { data: employeesData, isLoading: employeesDataLoading } = useGetEmployeeDropDownByProjectQuery(urlProjectId, {
    skip: !urlProjectId,
  });

  return (
    <div>
      <Row>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">
            Worker and Site Area Details
          </h5>
          <Button color="primary" onClick={() => setIsAddModalOpen(true)}>
            <i className="ri-add-line align-bottom me-1"></i> Add Work Location
          </Button>
        </div>
      </Row>

      <Row className="mt-4">
        {permitData?.work_area_allocation?.length > 0 ? (
          permitData.work_area_allocation.map((workArea: any) => (
            <Col md={12} key={workArea.id} className="mb-4">
              <div className="d-flex align-items-center mb-4">
                <h5 className="card-title flex-grow-1 mb-0">
                  {workArea.work_location}
                </h5>
                <div className="mt-2">
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                      setSelectedId(workArea.id);
                      setIsEmployeeModalOpen(true);
                    }}
                  >
                    <i className="ri-user-add-line align-middle"></i> Add Employee
                  </Button>{" "}
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => {
                      setSelectedId(workArea.id);
                      setDeleteType("section");
                      setIsDeleteModalOpen(true);
                    }}
                    disabled={workArea.employee?.length > 0}
                  >
                    <i className="ri-delete-bin-5-line align-middle"></i> Delete Location
                  </Button>
                </div>
              </div>
              <Table className="table-borderless align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Employee Name</th>
                    <th>Position</th>
                    <th>Employee Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {workArea.employee?.length > 0 ? (
                    workArea.employee.map((emp: any) => (
                      <tr key={emp.id}>
                        <td>{emp.employee_fullname}</td>
                        <td>{emp.position_name || ""}</td>
                        <td>{emp.employee_number}</td>
                        <td>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => {
                              setSelectedId(workArea.id);
                              setSelectedEmployee(emp.id);
                              setDeleteType("employee");
                              setIsDeleteModalOpen(true);
                            }}
                          >
                            <i className="ri-delete-bin-5-line align-middle"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No employees assigned.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          ))
        ) : (
          <Col md={12}>
            <div className="text-center">No work locations added yet.</div>
          </Col>
        )}
      </Row>

      {/* Modals */}
      <AddWorkLocationModal
        isOpen={isAddModalOpen}
        toggle={() => setIsAddModalOpen(!isAddModalOpen)}
        workLocation={workLocation}
        setWorkLocation={setWorkLocation}
        isSubmitting={isUpdateLoading}
        onSubmit={handleAddWorkLocation}
        isPermitFetching={isPermitFetching}
      />

      <AddMultipleEmployeeModal 
        isModalOpen={isEmployeeModalOpen}
        handleModalToggle={() => setIsEmployeeModalOpen(!isEmployeeModalOpen)}
        projectId={urlProjectId}
        submitLoading={isUpdateLoading}
        handleSubmit={(employeeData: any) => {
          console.log('employeeData---iner', employeeData)
            setSelectedEmployee(employeeData); 
            handleAddEmployeeToWorkArea(employeeData);
          }
        }
      />

      <WorkerDeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onDelete={handleDeleteWorkLocationOrEmployee}
        isDeleting={isDeleteLoading}
        isPermitFetching={isPermitFetching}
      />

      <div className="d-flex justify-content-between mt-3">
        <Button color="secondary" onClick={previousStep}>
          Previous
        </Button>
        <Button
          color="primary"
          disabled={!permitData?.work_area_allocation?.length}
          onClick={nextStep}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Step3WorkAreaAllocation;
