// src/index.tsx or src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import { loginSlice } from "./slices/auth/login/loginSlice";
import { dashboardSlice } from "./slices/dashboardProject/dashboardSlice";
import { employeeSlice } from "./slices/employee/employeeSlice";
import { taskSlice } from "./slices/tasks/taskSlice";
import { projectSlice } from "./slices/projects/projectSlice";
import { inspectionReportSlice } from "slices/InspectionReport/InspectionReportSlice";
import { workflowSlice } from "slices/workflow/WorkFlowSlice";
import { resourceInventorySlice } from "slices/resourceInventory/inventorySlice";
import { pageAccessSlice } from "slices/pageAccess/PageAccessSlice";
import { pageSettingsSlice } from "slices/pageSettings/pageSettingsSlice";
import { ServiceContextProvider } from "./context/ServiceContextProvider";
import { safetyAppSlice } from "slices/safetyApp/safetyappSlice";
import { notificationSlice } from "slices/notification/NotificationSlice";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loginSlice.middleware,
      dashboardSlice.middleware,
      employeeSlice.middleware,
      inspectionReportSlice.middleware,
      taskSlice.middleware,
      projectSlice.middleware,
      workflowSlice.middleware,
      resourceInventorySlice.middleware,
      pageAccessSlice.middleware,
      pageSettingsSlice.middleware,
      safetyAppSlice.middleware,
      notificationSlice.middleware
    ),
    devTools: true,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter basename="/">
        <ServiceContextProvider>
          <App />
        </ServiceContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
